import React from "react";

const InputBoxEditable = ({ label, name, value, onChange, type="text" }) => {
  return (
    <div className="relative w-full">
      <label
        htmlFor=""
        className="bg-white px-2 absolute top-[-15px] left-2 text-light"
      >
        {label}
      </label>
      <div className="flex justify-between items-center rounded border border-lightborder min-w-[220px]">
        <input
          type={type}
          name={name}
          value={value ? value : ""}
          onChange={onChange}
          className="outline-none w-full px-3 py-2 overflow-auto"
        ></input>
      </div>
    </div>
  );
};

export default InputBoxEditable;
