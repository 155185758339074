import React, { useEffect } from "react";
import Details from "../hostComponents/Details";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDriverDetails, getDriverEarnings, getDriverOrders, getDriverPendingEarnings } from "../../store/thunks/driverActions";
import DriverOrdersTable from "./DriverOrdersTable";


function DriverPersonalComponent() {

  const dispatch = useDispatch()
  const params = useParams()
  const { did } = params
  console.log("did", did);

  // Use Selectors -------------------------------
  const { loading: loadingDetails, driver, error: errorDetails } = useSelector((state) => state.driverDetails)
  const { loading: loadingOrders, orders, error: errorOrders } = useSelector((state) => state.driverOrders)

  useEffect(() => {
    dispatch(getDriverDetails(did))
    dispatch(getDriverOrders(did))
    dispatch(getDriverEarnings(did))
    dispatch(getDriverPendingEarnings(did))
  }, [])

  return (
    <div className="bg-[white] p-5">

      <Details link="driver" details={driver} loading={loadingDetails} />

      {orders && <DriverOrdersTable data={orders} />}
    </div>
  )
}

export default DriverPersonalComponent