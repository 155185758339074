import React, { useEffect } from 'react'
import DriverPersonalComponent from '../components/driverComponents/DriverPersonalComponent'
import AdminHeader from '../components/AdminHeader'
import { useDispatch, useSelector } from 'react-redux'
import { driverDetailReset } from '../store/slices/driverSlices'
import { Alert } from '@mui/material'

function DriverDetailsScreen() {
  const dispatch = useDispatch()

  const { driver, error: errorDetails } = useSelector((state) => state.driverDetails)

  useEffect(() => {
    setTimeout(() => {
      errorDetails && dispatch(driverDetailReset())
    }, 2000)
  }, [errorDetails])

  return (
    <div className="flex w-full h-screen overflow-auto">
      <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">

        {errorDetails && (
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <strong>{errorDetails}</strong>
          </Alert>
        )}

        <div className="px-10 bg-white">
          <AdminHeader heading={`${driver?.firstName} ${driver?.lastName}`} pages={"Pages / Drivers List / Driver"} />
        </div>

        <div className="p-10">
          <DriverPersonalComponent />
        </div>
      </div>
    </div>
  )
}

export default DriverDetailsScreen