import React from 'react'

const LoadingTableComponent = ({cols=10}) => {
    return (

        // <div className="flex flex-col justify-center gap-2 items-center w-full h-10 rounded-xl bg-lightgrey m-3">
        <tbody className=''>
            {

                Array.apply(null, Array(10)).map((x, index) => (

                    <tr>
                        {
                            Array.apply(null, Array(cols)).map((x, index) => (
                                <td className='bg-lightgrey h-14' key={index}>
                                    <div className='h-3 bg-white m-2 rounded'></div>
                                </td>
                            ))
                        }
                    </tr>
                ))
            }
        </tbody>
    )
}

export default LoadingTableComponent