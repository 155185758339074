import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Selectbox_months from './utilityComponents/Selectbox_months';
import request from '../utils/request'; // Assuming request is a utility for fetching data

const RevenueFunnel = ({ setError }) => {
    const [activePoint, setActivePoint] = useState(null);
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [earningsData, setEarningsData] = useState([]);

    const getGraphOrders = async () => {
        try {
            // Assuming `/orders` endpoint that accepts month and potentially year as query parameters
            const response = await request(`/orders?month=${month}`); // Adjusted to match your API call pattern
            // Process data to calculate earnings per day
            const dayEarnings = response.data.reduce((acc, order) => {
                const day = new Date(order.createdAt).getDate();
                const totalEarning = order.groceryFee + order.driverFee + order.hostFee + order.bellhoptFee + order.tip;
                if (!acc[day]) {
                    acc[day] = { day: day.toString(), earnings: 0 };
                }
                acc[day].earnings += totalEarning;
                return acc;
            }, {});

            setEarningsData(Object.values(dayEarnings).sort((a, b) => parseInt(a.day) - parseInt(b.day))); // Sort by day for chronological order
        } catch (error) {
            console.error("Failed to fetch order data:", error);
            setError("An error occurred"); // Simplified error handling
        }
    };

    useEffect(() => {
        getGraphOrders();
    }, [month]);

    return (
        <div className="w-[65%] p-5 shadow-4xl bg-white my-3 rounded-xl">
            <div className="w-full m-auto h-full">
                <div className="flex justify-between mb-5">
                    <h1 className="font-segoe font-bold text-[24px]">
                        Revenue Funnel
                    </h1>
                    <Selectbox_months month={month} setMonth={setMonth} />
                </div>

                <ResponsiveContainer width="100%" aspect={3}>
                    <LineChart data={earningsData} onMouseLeave={() => setActivePoint(null)}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey="earnings" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default RevenueFunnel;
