import classNames from 'classnames';
import React from 'react';

export default function SecondaryButton({
    children,
    ...props
}) {
    return (
        <button
            {...props}
            className={classNames(
                'font-small flex align-items justify-center p-1 px-4 rounded-[6px] flex gap-2 border w-[70px] ml-2',
                props.className,
            )}
        >
            {children}
        </button>
    );
}
