import React from 'react'
import { useState, useEffect } from "react";

const Selectbox_months = ({month, setMonth}) => {

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    return (
        <div className="flex items-center gap-2">
            <select id="monthSelect" className="bg-lightgrey p-2 rounded-xl cursor-pointer" value={month} onChange={(e) => setMonth(parseInt(e.target.value))}>
                {monthNames.map((month, index) => (
                    <option key={index} value={index + 1}>{month}</option>
                ))}
            </select>
        </div>
    )
}

export default Selectbox_months