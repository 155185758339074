import React, { useState } from 'react'
import searchIcon from "../../icons/search.png";
import notFoundImage from "../../icons/noProductFound.svg";
import nextIcon from "../../icons/next.svg";
import { getSearchQuery } from "../../utils/getSearchQuery";
import { updateTerm } from "../../store/slices/krogerSlices";
import { getProducts } from "../../store/thunks/krogerActions";
import PackageSelectCard from "./PackageSelectCard";
import LoadingHomeComponent from "./LoadingHomeComponent";
import { useSelector, useDispatch } from 'react-redux';

const KrogerStoreComponent = ({selectedProducts, setSelectedProducts, isPackageComplete, setIsPackageComplete, handleNext}) => {

    const dispatch = useDispatch();

    // useStates
    const [search, setSearch] = useState("");

    const { loading, products } = useSelector((state) => state.products);

    const revisedProducts =
      products &&
      products.filter((product) => {
        if (product.items[0].price) {
          return product;
        }
      });


    const handleSearchBtn = (e) => {
        e.preventDefault();
        const searchTerm = getSearchQuery(search);

        dispatch(updateTerm(searchTerm));
        dispatch(getProducts({ term: searchTerm }));
    };
    const handleEnterKey = (e) => {
        if (e.key === "Enter") {
            // e.preventDefault();
            document.querySelector("#searchBtn").click();
        }
    };


    return (
        <div>
            <div>
                <div className="relative">
                    <button
                        id="searchBtn"
                        onClick={handleSearchBtn}
                        className="w-[20px] absolute top-0 bottom-0 left-3"
                    >
                        <img className="w-full" src={searchIcon} alt="" />
                    </button>
                    <input
                        onKeyDown={handleEnterKey}
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        placeholder="Search product here"
                        className="w-full tablet:max-w-[260px] h-[30px] bg-lightgrey rounded-[20px] p-[20px] pl-[40px] outline-none"
                    />
                </div>

                <div className="grid grid-cols-3 gap-5 mt-5">
                    {loading && <LoadingHomeComponent />}

                    {!loading &&
                        products &&
                        revisedProducts &&
                        revisedProducts.map((product) => (
                            <PackageSelectCard
                                key={product._id}
                                product={product}
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                                isPackageComplete={isPackageComplete}
                                setIsPackageComplete={setIsPackageComplete}
                            />
                        ))}

                    {!loading && products && products.length === 0 && (
                        <div className="col-span-5 text-center text-lg p-20 mobile:p-5 mobile:text-base3">
                            <img
                                src={notFoundImage}
                                className="w-[200px] m-auto"
                                alt=""
                            />
                            <label className="text-3xl text-center pt-5 block">
                                No Products Found
                            </label>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex justify-end mt-5">
                <button
                    onClick={() => handleNext()}
                // onClick={() => setStep(step + 1)}
                >
                    <div className="flex gap-2 items-center">
                        <p className="text-[16px] font-segoe font-semibold text-[#EF4949]">
                            Next
                        </p>
                        <img src={nextIcon} alt="next" />
                    </div>
                </button>
            </div>
        </div>
    )
}

export default KrogerStoreComponent