import React from 'react'

export const TimeAndDateFormat = {

    getFormattedDate: (date) => {
        let formattedDate;
        if (date) {
            const dateObject = new Date(date);
            formattedDate = dateObject.toISOString().slice(0, 10);
        }
        return formattedDate;
    },

    fireStoreTimeStampToTime: (timeAndDate) => {
        if (!timeAndDate) {
            return '';
        }
        const createdAtDate = new Date(timeAndDate.seconds * 1000 + timeAndDate.nanoseconds / 1000000);
        const hours = createdAtDate.getHours().toString().padStart(2, '0');
        const minutes = createdAtDate.getMinutes().toString().padStart(2, '0');
        const seconds = createdAtDate.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    },
    getFormattedDateAndTime24: (date) => {
        if (!date) return null;

        const dateObject = new Date(date);

        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const day = String(dateObject.getDate()).padStart(2, '0');

        const hours = String(dateObject.getHours()).padStart(2, '0');
        const minutes = String(dateObject.getMinutes()).padStart(2, '0');
        const seconds = String(dateObject.getSeconds()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    },
    getFormattedDateAndTime12: (date) => {
        if (!date) return null;

        const dateObject = new Date(date);

        const day = dateObject.getDate();
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const month = monthNames[dateObject.getMonth()];
        const year = dateObject.getFullYear();

        let hours = dateObject.getHours();
        const minutes = String(dateObject.getMinutes()).padStart(2, '0');

        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        const formattedDate = `${day}, ${month} ${year} ${hours}:${minutes} ${ampm}`;

        return formattedDate;
    }

}