import React, { useState } from 'react';
import DialogModal from './DialogModal';
import SecondaryButton from './SecondaryButton';
import request from '../utils/request';
import { useNavigate } from 'react-router-dom';

export default function ChangeOrderStatus({ entry, setMessage }) {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);

    const handleSubmit = async () => {
        if (entry.status === 'picked' && images.length === 0) {
            setMessage('You must upload at least one image.');
            return;
        }

        setLoading(true)
        try {
            const formData = new FormData();

            if (entry.status === 'picked') {

                images.forEach((image, index) => {
                    formData.append("proofImages", image, `image${index}.jpg`);
                });

                await request.patch(`orders/status/${entry._id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                const status = 'reachedHome';
                const secondStatus = 'delivered';
                await request.post(
                    `/orders/driver/update-status/${entry._id}`,
                    {
                      status,
                      lng: entry.property?.address?.lng,
                      lat: entry.property?.address?.lat,
                    }
                );
                request.post(
                    `/orders/driver/update-status/${entry._id}`,
                    {
                      status: secondStatus,
                      lng: entry.property?.address?.lng,
                      lat: entry.property?.address?.lat,
                    }
                );
            } else {
                await request.patch(`orders/status/${entry._id}`, { status: 'picked' });
                const status = 'reachedStore'
                request.post(
                    `/orders/driver/update-status/${entry._id}`,
                    {
                      status,
                      lng: entry.storeLng,
                      lat: entry.storeLat,
                    }
                );
            }

            setOpen(false);
            navigate('/manage-order-status');
            setMessage('Order status has been updated.');
            setLoading(false)

        } catch (error) {
            setLoading(false)
            console.error("Error updating order status:", error);
            setMessage('An error occurred while updating the order status.');
        }
    };

    const handleImageChange = (e) => {
        setImages(Array.from(e.target.files));
    };

    return (
        <div>
            <div
                className='mr-2 px-2 md:px-4 h-[30px] flex items-center cursor-pointer justify-center border border-gray-400 rounded-md whitespace-nowrap overflow-hidden text-ellipsis'
                onClick={() => setOpen(true)}
            >
                Manage Status
            </div>
            <DialogModal openModal={open} setOpenModal={setOpen}>
                <DialogModal.Content title={'Update Order Status'}>
                    <form className="flex justify-between rounded">
                        <div className='w-full'>
                            <div className="mb-4 ml-2 flex flex-col space-y-4 justify-center items-center">
                                {
                                    entry.status === 'pending' && (
                                        <label className="block text-gray-700 text-sm font-bold mb-2 cursor-pointer" htmlFor="change-status">
                                            Click following button to change status to
                                        </label>
                                    )
                                }
                                {entry.status === 'pending' ? (
                                    <button type="button" onClick={handleSubmit} className='rounded-[6px] border p-2 h-[30px] flex items-center justify-center'>
                                        Picked From Store
                                    </button>
                                ) : entry.status === 'picked' ? (
                                    <>
                                        <input
                                            type="file"
                                            multiple
                                            onChange={handleImageChange}
                                            accept="image/*"
                                            className="hidden"
                                            id="proof-images"
                                        />
                                        <label htmlFor="proof-images" className="rounded-[6px] border p-2 h-[30px] flex items-center justify-center cursor-pointer">
                                            Select Images
                                        </label>
                                        {images.length > 0 && (
                                            <ul className="mt-2">
                                                {images.map((image, index) => (
                                                    <li key={index} className="text-sm text-gray-600"> {image.name}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </form>
                </DialogModal.Content>
                <DialogModal.Footer>                    
                    {
                        entry.status === 'picked' ? (<SecondaryButton onClick={handleSubmit} disabled={images.length === 0} className='p-2 border-blue text-blue'>{loading ? 'Saving....' : 'Save'}</SecondaryButton>) : ''
                    }
                    <SecondaryButton onClick={() => setOpen(false)} className='p-2 border-red text-red'>Cancel</SecondaryButton>
                </DialogModal.Footer>
            </DialogModal>
        </div>
    );
}
