import React, { useEffect, useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { DriverPromotionService } from '../services/DriverPromotionService';
import DriverPromotionForm from '../components/DriverPromotionForm';
import request from '../utils/request';
import { TimeAndDateFormat } from '../utils/TimeAndDateFormat';

const TrackOrder = () => {
    const navigate = useNavigate();
    const [orderTrack, setOrderTrack] = useState('')
    const params = useParams();
    const { oid } = params;

    useEffect(() => {
        if (oid) {
            fetchOrder(oid);
        } else {
            navigate('/orderList');
        }
    }, [])
    const [message, setMessage] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [timeTracking, setTimeTracking] = useState(false);


    const fetchOrder = async (oid) => {
        setLoading(true)
        const response = await request.get('/admin/track-order/' + oid);
        const timeTrackingResponse = await request.get('/admin/get-delivery-time/' + oid);
        setTimeTracking(timeTrackingResponse.data)
        setOrderTrack(response.data);
        setLoading(false)
    }

    const statuses = [
        { key: 'reachedStore', label: 'At store', message: 'Driver Reached at store.' },
        // { key: 'leaveStore', label: 'Left store', message: 'Driver left the store.' },
        { key: 'way-to-home', label: 'On the way', message: 'Driver is on the way to destination.' },
        { key: 'reachedHome', label: 'At home', message: 'Driver reached at home.' },
        { key: 'delivered', label: 'Delivered', message: 'Order has been delivered.' },
    ];

    const getStatusIcon = (isPresent, event, status) => {
        if (isPresent) {
            return (
                <>
                    <span
                        className={`absolute flex items-center justify-center w-10 h-10 -left-5 rounded-full ring-8 ring-white dark:ring-gray-900 bg-blue-100 dark:bg-blue-800 text-blue-600`}
                        style={{ background: 'black', color: 'white' }}
                    >
                        <svg className="w-3.5 h-3.5 lg:w-4 lg:h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                        </svg>
                    </span>
                    <h2 className="text-xl font-bold ml-4">{status?.label}</h2>
                    <p className="text-gray-600 text-bold ml-4">{status?.message}</p>
                    <span className="ml-4 text-sm">
                        {TimeAndDateFormat.getFormattedDateAndTime12(event?.timestamp)}
                    </span>
                </>
            );
        } else {
            return (
                <>
                    <span
                        className={`absolute flex items-center justify-center w-10 h-10 -left-5 rounded-full ring-8 ring-white dark:ring-gray-900 bg-red-500 text-white`}
                        style={{ background: '#cccccc', color: 'white' }}
                    >
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{color: 'grey'}}>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01-6h.01M19 13a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </span>
                    <h2 className="text-xl font-bold ml-4">{status?.label}</h2>
                </>
            );
        }
    };

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-6 py-5 h-full overflow-auto ">
                    <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 min-h-[60%] mt-1 items-center justify-center flex">
                        {
                            loading && <CircularProgress />
                        }
                        <div className="w-1/2 ">
                            <h1 className='text-xl font-bold mt-4 flex items-center'>
                                Order Tracking Details
                                {
                                    timeTracking && (<span className='ml-auto'>
                                        Time
                                        &nbsp; {timeTracking?.time[timeTracking?.time?.length - 1]?.totalTimeTaken?.da} (Days
                                        &nbsp; {timeTracking?.time[timeTracking?.time?.length - 1]?.totalTimeTaken?.hours} Hours
                                        &nbsp; {timeTracking?.time[timeTracking?.time?.length - 1]?.totalTimeTaken?.minutes} Minutes
                                        &nbsp; {timeTracking?.time[timeTracking?.time?.length - 1]?.totalTimeTaken?.seconds} Seconds)
                                    </span>)
                                }
                            </h1>
                            <hr className="my-3 gray-600 opacity-25" />
                            {
                                orderTrack && (
                                    <>
                                        <div className="mb-4">
                                            <p className="text-gray-600 my-2">Guest Email: <span className='text-xl font-bold'>{orderTrack?.order?.guestEmail}</span></p>
                                            <p className="text-gray-600 my-2">Guest Number: <span className='text-xl font-bold'>{orderTrack?.order?.guestPhoneNumber}</span></p>
                                            <p className="text-gray-600 my-2">Delivery Address: <span className='text-xl font-bold'>{orderTrack?.propertyAddress?.address?.line1 + ', ' + orderTrack?.propertyAddress?.address?.line2}</span></p>
                                            <p className="text-gray-600 my-2">Store Address: <span className='text-xl font-bold'>{orderTrack?.order?.storeLocation + ', ' + orderTrack?.propertyAddress?.address?.line2}</span></p>
                                            <p className="text-gray-600 my-2">Driver Name: <span className='text-xl font-bold'>{orderTrack?.order?.driverID?.firstName + ' ' + orderTrack?.order?.driverID?.lastName}</span></p>
                                            <p className="text-gray-600 my-2">Host Name: <span className='text-xl font-bold'>{orderTrack?.propertyAddress?.hostID?.firstName + ' ' + orderTrack?.propertyAddress?.hostID?.lastName}</span></p>
                                            <p className="text-gray-600 my-2">Host Number: <span className='text-xl font-bold'>{orderTrack?.propertyAddress?.hostID?.phoneNumber}</span></p>
                                        </div>
                                        <hr className="my-3 gray-600 opacity-25" />
                                        <div className="relative border-l border-gray-200 dark:border-gray-700 ml-4">
                                            {statuses?.map((status, index) => {
                                                const event = orderTrack?.order?.driverStatus?.find(e => e.status === status.key);
                                                const isPresent = Boolean(event);

                                                return (
                                                    <div key={index} className="mb-10 ml-6">
                                                        {getStatusIcon(isPresent, event, status)}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default TrackOrder