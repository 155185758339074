import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHostDetails, getHostPendingEarnings, getHostProperties } from "../../store/thunks/HostActions";
import { useParams } from "react-router-dom";
import Details from "./Details";
import HostPropertiesTable from "./HostPropertiesTable";
import { getHostEarnings } from '../../store/thunks/HostActions';
import EmailAutomationComponent from "./EmailAutomationComponent";
import AllParsersComponent from "../parserComponents/AllParsersComponent";


function HostPersonalComponent({ setSuccessMessage, setErrorMessage}) {

  const dispatch = useDispatch()
  const params = useParams()
  const { hid } = params


  // Use Selectors -------------------------------
  const { loading: loadingDetails, host, error: errorHostDetails } = useSelector((state) => state.hostDetails)
  const { loading: loadingProperties, properties, error: errorProperties } = useSelector((state) => state.hostProperties)

  useEffect(() => {
    console.log("hid ");
    dispatch(getHostDetails(hid))
    dispatch(getHostProperties(hid))
    dispatch(getHostEarnings(hid));
    dispatch(getHostPendingEarnings(hid))
  }, [])

  return (
    <div className="bg-[white] p-5">

      <Details details={host} loading={loadingDetails} link="host"/>

      {/* Email Automation List will go here */}
      <AllParsersComponent setErrorMessage={setErrorMessage} setSuccessMessage={setSuccessMessage}/>


      {/* host properties list get */}
      <div className="text-[24px] font-bold font-segoe flex gap-3 items-center mt-5">
        <p>Properties</p>
      </div>
      <HostPropertiesTable data={properties} />

      {/* <HostDetailsTable /> */}
    </div>
  );
}

export default HostPersonalComponent;
