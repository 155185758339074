import request from '../utils/request'

export const PropertyOfferService = {

    getHosts: async () => {
        try {
            const response = await request.get('/hosts')
            const responseData = response.data?.map((host) => {
                return {
                    value: host._id,
                    label: host.firstName + ' ' + host.lastName
                }
            });
            return [{
                label: 'Select All',
                value: 'all'
            }, ...responseData]
        } catch (error) {
            console.log(error)
        }
    },

    getOffers: async () => {
        try {
            const response = await request.get('/admin/offers')
            return response.data?.map((offer) => {
                return {
                    value: offer._id,
                    label: offer.title
                }
            })
        } catch (error) {
            console.log(error)
        }
    },

    fetchHostProperties: async (selectedHosts) => {
        try {
            if (!selectedHosts.length > 0) {
                return;
            }
    
            const allHosts = selectedHosts.map(item => item.value);
            const response = await request.post('/properties/hosts/properties', {
                hosts: allHosts
            });
            return {
                propertyOptionsData: response.data?.map((property) => {
                    return {
                        value: property.uniqueId,
                        label: property.address?.line1 + ' (' + property.address?.city + ') ' + property.address?.state
                    }
                }),
                properties: response.data
            }
        } catch (error) {
            console.log(error)
        }
    },

    savePropertyOffer: async (host, property, allProperties, offerId, link, usage) => {
        const hostsArray = host.map(item => item.value);
        const propertiesArrayUniqueIds = property.map(item => item.value);
        let propertiesFilteredArray = allProperties.filter(item => property.some(obj => obj.value === item.uniqueId));
        const propertiesArrayIds = propertiesFilteredArray.map(item => item._id);

        const formDataToSend = {
            hostId: hostsArray,
            propertyId: propertiesArrayUniqueIds,
            offerId: offerId,
            link: link,
            property: propertiesArrayIds,
            usage: usage
        }

        try {
            await request.post('/admin/offer/create-property-offer', formDataToSend);
        } catch (error) {
            console.error('Error:', error);
        }
    },

    updatePropertyOffer: async (host, property, allProperties, offerId, link, usage, oid) => {
        
        const hostsArray = host.map(item => item.value);
        const propertiesArrayUniqueIds = property.map(item => item.value);
        let propertiesFilteredArray = allProperties.filter(item => property.some(obj => obj.value === item.uniqueId));
        const propertiesArrayIds = propertiesFilteredArray.map(item => item._id);

        const formDataToSend = {
            hostId: hostsArray,
            propertyId: propertiesArrayUniqueIds,
            offerId: offerId,
            link: link,
            property: propertiesArrayIds,
            usage: usage
        }

        try {
            await request.patch('/admin/property-offer/' + oid, formDataToSend);
        } catch (error) {
            console.error('Error:', error);
        }
    },

    getPropertyOffer: async (oid) => {
        const response = await request.get('/admin/property-offer/' + oid);

        let data = {
            link: response.data?.offer?.link,
            hostId: response.data?.offer?.hostId,
            offerId: response.data?.offer?.offerId,
            propertyId: response.data?.offer?.propertyId,
            property: response.data?.offer?.property,
            usage: response.data?.offer?.usage,
            allHosts: response.data?.offer?.hostId.map((sHost) => {
                return {
                    value: sHost._id,
                    label: sHost.firstName + ' ' + sHost.lastName
                }
            }),
            allProperties: response.data?.offer?.property?.map((sProperty) => {
                return {
                    value: sProperty._id,
                    label: sProperty.address?.line1 + ' (' + sProperty.address?.city + ') ' + sProperty.address?.state
                }
            }),
            selectedOffer: {
                value: response.data?.offer.offerId._id,
                label: response.data?.offer.offerId.title
            },            
        };

        const allHostIds = data.allHosts.map(item => item.value);
        const propertiesResponse = await request.post('/properties/hosts/properties', {
            hosts: allHostIds
        })

        data.propertiesData = propertiesResponse.data?.map((property) => {
            return {
                value: property.uniqueId,
                label: property.address?.line1 + ' (' + property.address?.city + ') ' + property.address?.state
            }
        });

        data.propertiesResponse = propertiesResponse.data;

        return data;
    }
    
}