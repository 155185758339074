import React, { useEffect, useState } from 'react'
import cashIcon from '../../icons/cashIcon.svg'
import personalIcon from '../../icons/personalDetailIcon.svg'
import InputBox from '../utilityComponents/InputBox';
import { useDispatch, useSelector } from 'react-redux';

function Details({ details, loading, link }) {


  console.log("details ->>> ", details)

  const dispatch = useDispatch()

  const { loading: loadingHostEarnings, earnings: hostEarnings, errorHost } = useSelector((state) => state.hostEarnings)
  const { loading: loadingDriverEarnings, earnings: driverEarnings, errorDriver } = useSelector((state) => state.driverEarnings)
  const { earnings: driverPendingEarnings, error: errorDriverPendingEarnings } = useSelector((state) => state.driverPendingEarnings)
  const { earnings: hostPendingEarnings, error: errorHostPendingEarnings } = useSelector((state) => state.hostPendingEarnings)

  let earnings, pendingEarnings
  switch (link) {
    case 'host':
      earnings = hostEarnings
      pendingEarnings = hostPendingEarnings
      loading = loadingHostEarnings
      break;
    case 'driver':
      earnings = driverEarnings
      pendingEarnings = driverPendingEarnings
      loading = loadingDriverEarnings
      break;
  }
  let total = 0, pendingTotal = 0;
  for (const earning in earnings) {
    const sum = earnings[earning].earnings.reduce((acc, amount) => (acc + amount), 0)
    total += sum
  }
  for (const earning in pendingEarnings) {
    const sum = pendingEarnings[earning].earnings.reduce((acc, amount) => (acc + amount), 0)
    pendingTotal += sum
  }

  return (
    <div className="flex w-full justify-between gap-5">

      {/* -------------------- Personal Details ------------------ */}
      <div className="w-[60%] flex flex-col">


        <div className="text-[24px] font-bold font-segoe flex gap-3 items-center">
          <img src={personalIcon} alt="personaldetail" />
          <p>Personal Details</p>
        </div>

        {
          loading ? (

            <div className='w-full py-5 h-30 rounded bg-primar flex flex-col gap-8'>
              <div className='w-full h-10 rounded bg-lightgrey'></div>
              <div className='w-full h-10 rounded bg-lightgrey'></div>
            </div>
          ) :
            (
              <div className="flex flex-col gap-8 justify-between py-5">
                <div className="flex gap-5">
                  <InputBox name={"Name"} value={`${details?.firstName} ${details?.lastName}`} />
                  <InputBox name={"Email"} value={details?.email} icon={true} />
                </div>
                <div className="flex gap-5">

                  <InputBox name={"Phone"} value={details?.phoneNumber} icon={true} />
                  <InputBox name={"Address"} value={`${details?.address.line1} ${details?.address.line2 ? details?.address.line2 : ''}, ${details?.address.city}`} />
                </div>
              </div >
            )
        }

      </div>

      {/* -------------------- Earnings ------------------ */}

      <div className="w-[32%] max-w-[500px] flex flex-col">
        <div className="text-[24px] font-bold font-segoe flex gap-3 items-center">
          <img src={cashIcon} alt="cash-icon" />
          <p>Earnings</p>
        </div>

        <div className="flex gap-2 h-full py-5">
          <div className="flex flex-col justify-center items-center gap-2 w-[50%] border border-lightborder rounded-md p-1">
            {loading ? <h2 className='text-base3 font-bold'>$ ...</h2> : <h2 className='text-base3 font-bold'>${pendingTotal?.toFixed(2)}</h2>}
            <p className='text-base1 desktop:text-base2 text-light text-center'>Active Bookings</p>
          </div>
          <div className="flex flex-col justify-center items-center gap-2 w-[50%] border border-lightborder rounded-md">
            {loading ? <h2 className='text-base3 font-bold'>$ ...</h2> : <h2 className='text-base3 font-bold'>${total?.toFixed(2)}</h2>}

            <p className='text-base1 desktop:text-base2 text-light text-center'>This Month Earning</p>
          </div>
        </div>
      </div>

      {/* <h1 className="text-[24px] font-bold font-segoe pt-5">Orders List</h1> */}
    </div >
  )
}

export default Details