import React from 'react'
import LoadingTableComponent from './utilityComponents/LoadingTableComponent'
import { NavLink } from 'react-router-dom'
import DeleteOffer from './DeleteOffer'
import { TimeAndDateFormat } from '../utils/TimeAndDateFormat'

const OffersTable = ({ data, limit, loading, deleteRow }) => {

    return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-[black] text-[white]">
                <tr>
                    <th className="px-4 py-3">
                        title
                    </th>
                    <th className="px-4 py-3">
                        Banner Image
                    </th>
                    <th className="px-4 py-3">
                        Logo
                    </th>
                    <th className="px-4 py-3">
                        Is Show Form?
                    </th>
                    <th className="px-4 py-3">
                        Position
                    </th>
                    <th className="px-4 py-3">
                        After Product
                    </th>
                    <th className="px-4 py-3">
                        Expire At
                    </th>
                    <th className="px-4 py-3">
                        Actions
                    </th>
                </tr>
            </thead>
            {loading ? (
                <LoadingTableComponent cols={8} />
            ) : (
                data?.length > 0 && data.map((entry, index) => (
                    <tbody>
                        <tr
                            key={entry._id}
                            className="bg-white border-b-[#CCCCCC] border-b-[0.5px] py-4"
                        >
                            <td className="px-4 w-fit max-w-[170px]">
                                {entry.title}
                            </td>

                            <td className="px-4 w-fit max-w-[200px]">
                                <img src={entry.bannerImage} style={{
                                    height: '30px',
                                    width: '30px'
                                }} />
                            </td>

                            <td className="px-4 w-fit max-w-[200px]">
                                <img src={entry.logo} style={{
                                    height: '30px',
                                    width: '30px'
                                }} />
                            </td>

                            <td className="px-4 w-fit min-w-[120px]">
                                {entry.showForm ? 'Show' : 'Hide'}
                            </td>

                            <td className="px-4 w-fit min-w-[120px]">
                                {entry.position}
                            </td>

                            <td className="px-4 w-fit min-w-[50px]">
                                {entry.afterProduct}
                            </td>

                            <td className="px-4 w-fit min-w-[50px] ">
                                {TimeAndDateFormat.getFormattedDate(entry.expireAt)}
                            </td>

                            <td className="py-4 px-4 w-fit flex m-2">
                                <NavLink to={`/offer/edit/${entry._id}`} className="p-1 px-4 rounded-[6px] flex gap-2 border border w-[60px]">
                                    <p>Edit</p>
                                </NavLink>
                                <DeleteOffer offerId={entry._id} deleteRow={deleteRow} />
                            </td>
                        </tr>
                    </tbody>
                )
                )
            )}
        </table>
    )
}

export default OffersTable