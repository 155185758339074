// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBsli1PTkHPDfQcjAvw6tmRtXAyN_ry64s",
  authDomain: "bellhopt-db0b5.firebaseapp.com",
  projectId: "bellhopt-db0b5",
  storageBucket: "bellhopt-db0b5.appspot.com",
  messagingSenderId: "2857934369",
  appId: "1:2857934369:web:0bd0a59e2695d9a2ff8d60",
  measurementId: "G-TDQ48JSGDC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);