import { getProductDetailsKroger } from './kroger';
import request from './request';

export const getPackagesKroger = async (packages) => {

    const { data } = await request.get("/kroger/");
    // console.log(data.access_token);

    const promises = packages.map(async (p) => {
        return Promise.all(p.products.map(async (prodId) => {
            if(prodId.length === 24) {
                const { data: {data: product} } = await request.get(`/products/${prodId}`);
                return product;
            }else {
                return getProductDetailsKroger(prodId, data.access_token);
            }
        }))
    });
    const temp = await Promise.all(promises);
    for (let i = 0; i < temp.length; i++) {
        temp[i] = { packageId: packages[i]._id, image: packages[i].image, products: temp[i], name: packages[i].name, isActive: packages[i].isActive };
    }
    return temp;
}