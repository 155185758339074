import React, { useEffect, useState } from "react";
import request from "../../utils/request";
import OwnPackageProduct from "./OwnPackageProduct";
import plus from "../../icons/+.svg";

// setError --> coming from parent component
// _setError --> local Error

const OwnProductContainer = ({
  selectedProducts,
  setSelectedProducts,
  isPackageComplete,
  setIsPackageComplete,
  setStep,
}) => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await request.get("/products/");
        setProducts(data.products);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="grid grid-cols-3 gap-5 mt-5">
      {products?.map((product, index) => (
        <OwnPackageProduct
          key={index}
          product={product}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          isPackageComplete={isPackageComplete}
          setIsPackageComplete={setIsPackageComplete}
        />
      ))}




      <div className="w-[220px] h-[244px] flex justify-center items-center cursor-pointer"
        onClick={() => setStep(3)}
      >
        <div className="flex flex-col items-center gap-3">
          <img src={plus} alt="plus" className="w-[30px] h-[30px]" />
          <p>Add another product</p>
        </div>
      </div>
    </div>
  );
};

export default OwnProductContainer;
