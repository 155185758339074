import React, { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { Alert } from '@mui/material';
import SelectDropdown from '../components/SelectDropdown';
import { PropertyOfferService } from '../services/PropertyOfferService';

const PropertyOfferForm = ({ message, setMessage, handleSubmit, formData, setFormData, properties, loading, error, selectDropdown }) => {

    const [hosts, setHosts] = useState([]);
    const [offers, setOffers] = useState([]);

    useEffect(() => {
        fetchHosts();
        fetchOffers();
    }, [])

    const fetchOffers = async () => {
        const response = await PropertyOfferService.getOffers();
        setOffers(response);
    }

    const fetchHosts = async () => {
        const response = await PropertyOfferService.getHosts();
        setHosts(response);
    }

    const useageOptions = [
        {
            label: 'One Time',
            value: 'oneTime'
        },
        {
            label: 'Two Time',
            value: 'twoTime'
        },
        {
            label: 'Unlimited',
            value: 'unlimited'
        },
    ]

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const setHostOptions = (type, options) => {
        const option = options.filter((option) => option.value === 'all');
        if(option.length > 0) {
            const hostData = hosts.filter((host) => host.value !== 'all');
            selectDropdown(type, hostData)
        } else {
            selectDropdown(type, options)
        }
    }

    return (
        <>
            {message &&
                <Alert
                    severity="success"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 20,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {message}
                </Alert>
            }
            {error &&
                <Alert
                    severity="error"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 20,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {error}
                </Alert>
            }
            <div className="px-6 py-5 h-full overflow-auto w-full">
                <form onSubmit={handleSubmit} className="flex bg-white justify-between rounded px-8 pt-6 pb-8 mb-4">
                    <div className='w-1/2'>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                                Select Host
                            </label>
                            <SelectDropdown
                                value={formData.host}
                                options={hosts}
                                isMulti={true}
                                onChange={option => setHostOptions('host', option)}
                            />
                        </div>

                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                                Select Offer
                            </label>
                            <SelectDropdown
                                value={formData.offer}
                                options={offers}
                                onChange={option => selectDropdown('offer', option)}
                            />
                        </div>
                        <div className="mb-4 ml-2" >
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="link">
                                Link/Flow Id
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="link"
                                type="link"
                                placeholder="Enter Link"
                                name="link"
                                value={formData.link}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                                Select Property
                            </label>
                            <SelectDropdown
                                value={formData.property}
                                isMulti={true}
                                options={properties}
                                onChange={option => selectDropdown('property', option)}
                            />
                        </div>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="link">
                                Usage
                            </label>
                            <SelectDropdown
                                value={formData.useage}
                                options={useageOptions}
                                onChange={option => selectDropdown('useage', option)}
                            />
                        </div>
                        <div className="flex items-center justify-end mt-10">
                            <button
                                className="bg-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}

export default PropertyOfferForm