import React from 'react'
import copyIcon from '../../icons/copy.svg'

const InputBox = ({ name, value, icon }) => {

    // Handlers
    const handleCopyBtn = (content) => {
        navigator.clipboard.writeText(content);
        window.alert("Unique Link Copied")
    }

    return (
        <div className='relative w-full'>
            <label htmlFor="" className='bg-white px-2 absolute top-[-15px] left-2 text-light'>{name}</label>
            <div className='flex justify-between items-center rounded border border-lightborder min-w-[220px]'>

                <input type="text" value={value? value: ''} className='outline-none w-full px-3 py-2 overflow-auto' readOnly></input>
                {icon && <img src={copyIcon} alt="copy-image" className=' cursor-pointer mr-2' onClick={handleCopyBtn} />}
            </div>
        </div>
    )
}

export default InputBox