import request from "../../utils/request";
import { createAsyncThunk } from "@reduxjs/toolkit";

const getAllHosts = createAsyncThunk(
    "allHosts/getAllHosts",
    async ({limit, status}, { rejectWithValue }) => {

        let url = `/hosts?status=${status}`
        if (limit){
            url = `/hosts?limit=${limit}&status=${status}`
        }
        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

const getHostDetails = createAsyncThunk(
    "hostDetails/getHostDetails",
    async (hid, { rejectWithValue }) => {
        try {
            const { data } = await request.get(`/hosts/${hid}`, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

const getHostProperties = createAsyncThunk(
    "hostProperties/getHostProperties",
    async (id, { rejectWithValue }) => {
        try {
            const { data } = await request.get(`/properties/host/${id}`, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const deleteHostAction = createAsyncThunk(
    "deleteHost/deleteHostAction",
    async (hid, { rejectWithValue }) => {
        try {
            const { data } = await request.delete(`/hosts/${hid}`, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getHostEarnings = createAsyncThunk(
    "hostEarnings/getHostEarnings",
    async (hid, { rejectWithValue }) => {

        let url = `/orders/graph/${hid}?type=host&duration=30&status=completed`;
        
        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getHostPendingEarnings = createAsyncThunk(
    "hostPendingEarnings/getHostPendingEarnings",
    async (hid, { rejectWithValue }) => {

        let url = `/orders/graph/${hid}?type=host&duration=30&status=pending,picked,requested`;
        
        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export { getAllHosts, getHostDetails, getHostProperties, deleteHostAction, getHostEarnings, getHostPendingEarnings }