import React, { useEffect, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import HostPersonalComponent from "../components/hostComponents/HostPersonalComponent";
import { Alert } from "@mui/material";
import {
  deletePropertyReset,
  updatePropertyReset,
} from "../store/slices/propertySlices";
import { useDispatch, useSelector } from "react-redux";
import { getHostProperties } from "../store/thunks/HostActions";
import { useParams } from "react-router-dom";

function HostDetailScreen() {
  const dispatch = useDispatch();
  const params = useParams();
  const { hid } = params;

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { host, errorDetails } = useSelector((state) => state.hostDetails);
  const {
    success: successUpdate,
    message: messageUpdate,
    error: errorUpdate,
  } = useSelector((state) => state.propertyUpdate);
  const {
    success: successDelete,
    message: messageDelete,
    error: errorDelete,
  } = useSelector((state) => state.propertyDelete);

  useEffect(() => {
    if (
      successUpdate ||
      successDelete ||
      errorDelete ||
      errorUpdate ||
      successMessage ||
      errorMessage
    ) {
      setTimeout(() => {
        successUpdate && dispatch(updatePropertyReset());
        errorUpdate && dispatch(updatePropertyReset());
        successDelete && dispatch(deletePropertyReset());
        errorDelete && dispatch(deletePropertyReset());

        setSuccessMessage(null);
        setErrorMessage(null);
      }, 2000);
    }

    if (errorDelete || errorUpdate) {
      errorDelete && setErrorMessage(errorDelete);
      errorUpdate && setErrorMessage(errorUpdate);
    }
    if (successDelete || successUpdate) {
      successDelete && setSuccessMessage(messageDelete);
      successUpdate && setSuccessMessage(messageUpdate);
    }
    if (successUpdate || successDelete){
      dispatch(getHostProperties(hid));
      console.log("dispatched");
    } 
  }, [successUpdate, successDelete, dispatch, successMessage, errorMessage]);

  return (
    <div className="flex w-full h-screen overflow-auto">
      <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
        {/* -------------------------------- Alert Section -----------------------------------*/}
        {errorDetails && (
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <strong>{errorDetails}</strong>
          </Alert>
        )}

        {errorMessage && (
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <strong>{errorMessage}</strong>
          </Alert>
        )}

        {successMessage && (
          <Alert
            severity="success"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <strong>{successMessage}</strong>
          </Alert>
        )}
        {/* --------------------------------------------------------------- */}

        <div className="px-10 bg-white">
          <AdminHeader
            heading={`${host?.firstName} ${host?.lastName}`}
            pages={"Pages / Properties Hosts List / Host"}
          />
        </div>

        <div className="px-6 py-5 h-screen overflow-auto">
          <HostPersonalComponent
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
          />
        </div>
      </div>
    </div>
  );
}

export default HostDetailScreen;
