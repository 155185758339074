import React from "react";
import { useState, useEffect } from "react";
import barsIcon from '../icons/bar_graph_icon.png'
import orderIcon from '../icons/order_red_icon.png'
import { useDispatch, useSelector } from "react-redux";
import RevenueFunnel from "./RevenueFunnel";
import TotalOrders from "./TotalOrders";
import RecentOrders from "./RecentOrders";
import GuestMap from "./GuestMap";
import request from "../utils/request";
import { Alert } from "@mui/material";
import axios from "axios";


const data = [
  { day: "1", earnings: 0 },
  { day: "2", earnings: 0 },
  { day: "3", earnings: 0 },
  { day: "4", earnings: 0 },
  { day: "5", earnings: 0 },
  { day: "6", earnings: 0 },
  { day: "7", earnings: 0 },
  { day: "8", earnings: 0 },
  { day: "9", earnings: 0 },
  { day: "10", earnings: 0 },
  { day: "11", earnings: 0 },
  { day: "12", earnings: 0 },
  { day: "13", earnings: 0 },
  { day: "14", earnings: 0 },
  { day: "15", earnings: 0 },
  { day: "16", earnings: 0 },
  { day: "17", earnings: 0 },
  { day: "18", earnings: 0 },
  { day: "19", earnings: 0 },
  { day: "20", earnings: 0 },
  { day: "21", earnings: 0 },
  { day: "22", earnings: 0 },
  { day: "23", earnings: 0 },
  { day: "24", earnings: 0 },
  { day: "25", earnings: 0 },
  { day: "26", earnings: 0 },
  { day: "27", earnings: 0 },
  { day: "28", earnings: 0 },
  { day: "29", earnings: 0 },
  { day: "30", earnings: 0 }
];


function DashboardComponent() {

  // Get Company Earnings From stripe
  const [companyEarnings, setCompanyEarnings] = useState(0);
  const [monthlyRevenue, setMonthlyRevenue] = useState(0);
  const [count, setCount] = useState(0);
  const [error, setError] = useState();



  const [data, setData] = useState([]);

  useEffect(() => {
    // Make a GET request using Axios
    request.get('/properties/count')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);



  // useSelector ----------------
  const { adminInfo: { _id: hid } } = useSelector((state) => state.login)

  const getOrdersCount = async () => {
    let url = `/orders/count`
    const response = await request.get(url, {
      withCredentials: true,

    });
    const { data: { orderAllCount }, status } = response

    setCount(orderAllCount)
  }

  const getCompanyEarnings = async () => {
    let url = `/orders/graph/${hid}?type=admin&status=completed`;
    const response = await request.get(url, {
      withCredentials: true,

    });
    const { data: { orders }, status } = response

    let total = 0
    if (status === 200) {
      for (const earning in orders) {
        const sum = orders[earning].earnings.reduce((acc, amount) => (acc + amount), 0)
        total += sum
      }
      setCompanyEarnings(total)
    }
  }

  const getMonthlyRevenue = async () => {
    let url = `/orders/graph/${hid}?type=all&duration=30&status=pending,requested,picked,completed`;
    const response = await request.get(url, {
      withCredentials: true,

    });
    const { status } = response
    if (status === 200) {
      let total = 0
      const { data: { orders } } = response
      // console.log("orders revenue", orders);
      for (const monthOrders of orders) {
        const sum = monthOrders.earnings.reduce((acc, { bellhoptFee, hostFee, driverFee }) => (acc + bellhoptFee + hostFee + driverFee), 0);
        total += sum
      }
      setMonthlyRevenue(total)
    }
  }

  useEffect(() => {
    getCompanyEarnings()
    getMonthlyRevenue()
    getOrdersCount()
  }, [getMonthlyRevenue, getCompanyEarnings]);

  useEffect(()=>{
    setTimeout(()=>{
      error && setError('')
    }, 2000)
  }, [error])

  return (
    <div className="flex flex-col justify-between">
      <div className="flex items-center p-3 justify-around shadow-4xl bg-white my-3 rounded-xl w-full py-5 gap-x-1">
        
      {error && (
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <strong>{error}</strong>
          </Alert>
        )}
        
        <div className="w-[30%] border-r-[2px]">
          <div className="flex gap-5 items-center content-center">
            <div className="min-w-[50px] w-[50px] h-[50px] rounded-full bg-[#F5F5F5] flex justify-center items-center">
              <img src={barsIcon} alt="My Icon" className="" />
            </div>
            <div>
              <h5 className="font-segoe">Monthly Revenue</h5>
              <p className="text-base1 font-segoeBold">${monthlyRevenue? monthlyRevenue.toFixed(2): '.....'} </p>
            </div>
          </div>
        </div>

        <div className="w-[30%] border-r-[2px]">
          <div className="flex gap-5 items-center content-center">
            <div className="min-w-[50px] w-[50px] h-[50px] rounded-full bg-[#F5F5F5] flex justify-center items-center">
              <img src={orderIcon} alt="My Icon" className="" />
            </div>
            <div>
              <h5 className="font-segoe">Total Orders</h5>
              <p className="text-base1 font-segoeBold">{count ?count: '...'} </p>
            </div>
          </div>
        </div>



        <div className="w-[30%] border-r-[2px]">
          <div className="flex gap-5 items-center content-center">
            <div className="min-w-[50px] w-[50px] h-[50px] rounded-full bg-[#F5F5F5] flex justify-center items-center">
              <img src={orderIcon} alt="My Icon" className="" />
            </div>
            <div>
              <h5 className="font-segoe">Total Properties</h5>
              <p className="text-base1 font-segoeBold">{data.count} </p>
            </div>
          </div>
        </div>

        <div className="w-[30%]">
          <div className="flex gap-5 items-center content-center">
            <div className="min-w-[50px] w-[50px] h-[50px] rounded-full bg-[#F5F5F5] flex justify-center items-center">
              <img src={barsIcon} alt="My Icon" className="" />
            </div>
            <div>
              <h5 className="font-segoe">Company Earnings</h5>
              <p className="text-base1 font-segoeBold">${companyEarnings? companyEarnings.toFixed(2): '.....'}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between gap-5 h-fit">

        <RevenueFunnel error={error} setError={setError}/>

        <TotalOrders  setError={setError}/>
      </div>

      <div className="flex justify-between gap-5">

        <GuestMap />

        <RecentOrders  setError={setError}/>
      </div>
    </div>
  );
}

export default DashboardComponent;