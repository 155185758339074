import request from '../utils/request'

export const OfferService = {
    
    getOffer: async (oid) => {
        try {
            const response = await request.get('/admin/offer/' + oid)
            return response.data?.offer;
        }catch(error){
            console.log(error);
        }
    },

    updateOffer: async (oid, formData) => {
        const formDataToSend = new FormData();
        formDataToSend.append('title', formData.title);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('bannerImage', formData.bannerImage);
        formDataToSend.append('position', 'top');
        formDataToSend.append('afterProduct', formData.afterProduct);
        formDataToSend.append('expireAt', formData.expireAt);
        formDataToSend.append('logo', formData.logo);
        formDataToSend.append('showForm', formData.showForm);

        await request.patch('admin/offer/' + oid, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
    },

    createOffer: async (formData) => {
        const formDataToSend = new FormData();
        formDataToSend.append('title', formData.title);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('bannerImage', formData.bannerImage);
        formDataToSend.append('position', 'top');
        formDataToSend.append('afterProduct', formData.afterProduct);
        formDataToSend.append('expireAt', formData.expireAt);
        formDataToSend.append('logo', formData.logo);
        formDataToSend.append('showForm', formData.showForm);

        try {
            await request.post('admin/offer', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
        } catch (error) {
            console.error('Error:', error);
        }
    }
}