import { CircularProgress } from "@mui/material";
import React from "react";

const ToggleButton = ({ isChecked, update }) => {
  const handleChange = () => {
    update();
  };
  return (
    <>
      {/* toggle Button */}
      <label className="relative flex items-center cursor-pointer">
        <input
          type="checkbox"
          value={isChecked}
          onChange={update}
          className="sr-only peer"
        />
        <div
          className={`w-11 h-6 ${
            isChecked ? "bg-primary" : "bg-lightborder"
          } peer-focus:outline-none rounded-full ${
            isChecked ? "after:translate-x-full" : ""
          } peer-checked:after:border-white after:content-[''] after:absolute after:bg-white after:rounded-full after:h-5 after:top-[2px] after:left-[2px] after:w-5 after:transition-all dark:border-gray-600`}
        ></div>

        {/* {!false ? (
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            {isChecked ? "Active" : "InActive"}
          </span>
        ) : (
          <CircularProgress color="inherit" className="ml-4" size={"20px"} />
        )} */}
      </label>
    </>
  );
};

export default ToggleButton;
