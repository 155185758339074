import React, { useState, useEffect } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell
} from 'recharts';
import moment from 'moment-timezone';
import request from '../utils/request';
import { useDispatch } from 'react-redux';
import { getPropertyDetails } from '../store/thunks/propertyActions';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const details = payload[0].payload.details; // This references the 'details' array in each data point
    console.log(details)
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
        {details.map((detail, index) => (
          <div key={index}>
            <p>{`Arrival Time: ${detail.arrivalTime}`}</p>
            <p>{`Address: ${detail.address}`}</p>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const GuestMap = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchOrdersAndProperties = async () => {
      const startDate = moment().startOf('day').toISOString();
      const endDate = moment().add(7, 'days').endOf('day').toISOString();

      // Example API call - adjust to your API
      const ordersResponse = await request.get(`/orders?startDate=${startDate}&endDate=${endDate}`);
      const ordersData = ordersResponse.data;

      const dataWithProperties = await Promise.all(
        ordersData.map(async (order) => {
          const propertyDetails = await dispatch(getPropertyDetails(order.propertyID)).unwrap();
          return {
            ...order,
            address: `${propertyDetails.property.address.line1}, ${propertyDetails.property.address.city}, ${propertyDetails.property.address.state}, ${propertyDetails.property.address.zipCode}`,
            arrivalTime: moment.tz(order.inStockDate, "America/Los_Angeles").format('HH:mm A'), // Format time in PST
          };
        })
      );

      const processedData = processDataForGraph(dataWithProperties);
      setData(processedData);
    };

    fetchOrdersAndProperties();
  }, [dispatch]);

  const processDataForGraph = (dataWithProperties) => {
    let graphData = [];

    for (let i = 0; i < 7; i++) {
      const day = moment().add(i, 'days').format('YYYY-MM-DD');
      const dayOrders = dataWithProperties.filter(order => 
        moment(order.inStockDate).format('YYYY-MM-DD') === day
      );

      if (dayOrders.length > 0) {
        graphData.push({
          day,
          orders: dayOrders.length, // Number of orders for the bar graph
          details: dayOrders // Detailed orders for the tooltip
        });
      }
    }

    return graphData;
  };

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis allowDecimals={false} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="orders" fill="#8884d8">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GuestMap;
