import React from 'react'
import notFoundImage from '../icons/error_404.svg'
import Header from '../components/Header'

const PageNotFoundScreen = () => {
    return (
        <div className="flex flex-col h-screen w-full fixed">
            <Header />
            <div className="flex justify-center w-full h-full flex-grow items-center bg-white">
            <img src={notFoundImage} alt="not-found" />
            </div>
        </div>
    )
}

export default PageNotFoundScreen