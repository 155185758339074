import React, { useEffect, useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import { CircularProgress } from '@mui/material';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useNavigate, useParams } from "react-router-dom";
import OffersForm from '../components/OffersForm';
import { OfferService } from '../services/OfferService';

const OfferEdit = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { oid } = params;
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        bannerImage: '',
        position: '',
        afterProduct: 0,
        expireAt: '',
        showForm: '',
        logo: ''
    });

    useEffect(() => {
        if (oid) {
            fetchOffer();
        } else {
            navigate('/offers');
        }
    }, [])

    const fetchOffer = async () => {
        setLoading(true)
        const response = await OfferService.getOffer(oid);
        setFormData({
            title: response.title,
            description: response.description,
            bannerImage: response.bannerImage,
            position: response.position,
            afterProduct: response.afterProduct,
            expireAt: response.expireAt,
            showForm: response.showForm,
            logo: response.logo
        })
        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!formData.title || !formData.description || !formData.expireAt) {
            setError('All fields are required');
            setLoading(false);
            return;
        }

        let data = formData;
        console.log('data', data);
        data.showForm = data.showForm ? true : false;
        await OfferService.updateOffer(oid, data)

        setLoading(false);
        // Reset form data after successful submission if needed
        setFormData({
            description: '',
            bannerImage: null,
            position: '',
            afterProduct: '',
            expireAt: ''
        });
        setMessage('Offer has been updated')
        setTimeout(() => {
            navigate('/offers');
        }, 1500)
    };

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-10 bg-white">
                    <AdminHeader
                        heading={"Edit Offer"}
                        pages={"Pages / Offers / Edit Offer"}
                    />
                </div>
                <div className="px-6 py-5 h-full overflow-auto">
                    <div className="bg-white rounded-xl w-full min-h-[80%]" style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {
                            loading ? <CircularProgress /> : (
                                <OffersForm
                                    message={message}
                                    handleSubmit={handleSubmit}
                                    formData={formData}
                                    setFormData={setFormData}
                                    loading={loading}
                                    error={error}
                                />)
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default OfferEdit