import React, { useEffect, useState } from 'react'
import redCheckIcon from '../../icons/redCheck.svg'
import greyCheckIcon from '../../icons/greyCheck.svg'

const PackageSelectCard = ({ product, selectedProducts, setSelectedProducts, isEditMode }) => {

    const { productId, images, items, description, brand, categories } = product;
    const image = images[0].sizes[1].url;
    let revisedPrice = parseFloat(items[0].price?.regular);
    revisedPrice += revisedPrice * parseFloat(process.env.REACT_APP_PRODUCT_INCREMENT_PERCENT);
    revisedPrice = revisedPrice.toFixed(2)
    const soldBy = items[0].soldBy;
    const available = (items[0]?.inventory?.stockLevel === 'TEMPORARILY_OUT_OF_STOCK' ? false : true);

    // changing the items field of kroger to updated Field
    const revisedItems = items.map((item) => {
        return { ...items[0], price: { regular: revisedPrice } }
    })

    const [isSelected, setIsSelected] = useState();

    const handleProductAdd = () => {
        if (available) {
            if (isSelected) {
                setIsSelected(false);
                setSelectedProducts(selectedProducts?.filter((item) => item.productId !== productId));
            } else {
                setIsSelected(true);
                setSelectedProducts([...selectedProducts, { ...product, items: revisedItems }]);
            }
        }
    }

    useEffect(() => {
        if (product.items) {
            setIsSelected(selectedProducts?.some((item) => item.productId === productId))
        }
    }, [selectedProducts])

    return (
        <div
            className={`border-[1px] ${isSelected && !isEditMode ? "border-[red]" : "border-[#D9D9D9]"
                } relative cursor-pointer`}
            // } relative cursor-pointer ${isPackageComplete && !isSelected && 'cursor-not-allowed'}`}
            onClick={() => handleProductAdd()}
        >
            <div className="p-2 flex flex-col gap-2 justify-between h-full">

                <img className="w-[120px] h-[120px] mx-auto" src={image} />

                <h1 className="font-segoe font-bold text-base3">${revisedPrice} usd</h1>
                {!available && <p className="font-semibold text-base1 text-primary">(out of Stock)</p>}
                <p className='base-2'>{description}</p>
                <p className='mt-auto text-light'>(${revisedPrice} / {soldBy})</p>

                {/* Red Check Icon */}
                <div className="absolute right-1 top-1 flex ">
                    {/* {isEditMode && <img src={redCheckIcon} alt="red check" />} */}
                    {isEditMode ? <>

                    </>
                        : <>
                            {
                                isSelected ? (
                                    <img src={redCheckIcon} alt="red check" />
                                ) : (
                                    <img src={greyCheckIcon} alt="check" />
                                )}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default PackageSelectCard