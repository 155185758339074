import { createSlice } from "@reduxjs/toolkit";
import { deleteDriverAction, getAllDrivers, getDriverDetails, getDriverEarnings, getDriverOrders, getDriverPendingEarnings } from "../thunks/driverActions";

const allDriversSlice = createSlice({
  name: "drivers",
  initialState: {
    loading: false,
    success: false,
    driverList: null,
    error: "",
  },
  reducers: {
    driverListReset: (state) => {
      state.loading = false;
      state.success = false;
      state.driverList = null;
      state.error = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDrivers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDrivers.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.driverList = action.payload;
      })
      .addCase(getAllDrivers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const driverDetailSlice = createSlice({
  name: "driverDetails",
  initialState: {
    loading: false,
    success: false,
    driver: null,
    error: "",
  },
  reducers: {
    driverDetailReset: (state) => {
      state.loading = false;
      state.success = false;
      state.driver = null;
      state.error = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriverDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDriverDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.driver = action.payload;
      })
      .addCase(getDriverDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const driverOrdersSlice = createSlice({
  name: 'driverOrders',
  initialState: {
    loading: false,
    orders: [],
    error: ''
  },
  reducers: {
    driverOrdersReset: (state) => {
      state.loading = false;
      state.orders = [];
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriverOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDriverOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(getDriverOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error
      })
  }
})

const driverDeleteSlice = createSlice({
  name: 'deleteDriver',
  initialState: {
    loading: false,
    success: false,
    message: '',
    error: ''
  },
  reducers: {
    deleteDriverReset: (state) => {
      state.loading = false;
      state.success = false;
      state.message = '';
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteDriverAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDriverAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.message = action.payload.message;
      })
      .addCase(deleteDriverAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error
      })
  }
})

const driverEarningsSlice = createSlice({
  name: "driverEarnings",
  initialState: {
    loading: false,
    success: false,
    earnings: null,
    error: "",
  },
  reducers: {
    hostEarningsReset: (state) => {
      state.loading = false;
      state.success = false;
      state.earnings = null;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriverEarnings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDriverEarnings.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.earnings = action.payload.orders;
      })
      .addCase(getDriverEarnings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const driverPendingEarningsSlice = createSlice({
  name: "driverPendingEarnings",
  initialState: {
    loading: false,
    success: false,
    earnings: null,
    error: "",
  },
  reducers: {
    hostPendingEarningsReset: (state) => {
      state.loading = false;
      state.success = false;
      state.earnings = null;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDriverPendingEarnings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDriverPendingEarnings.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.earnings = action.payload.orders;
      })
      .addCase(getDriverPendingEarnings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

// export all actions from slices
export const { deleteDriverReset } = driverDeleteSlice.actions
export const {driverListReset} = allDriversSlice.actions
export const {driverDetailReset} = driverDetailSlice.actions
export const {driverOrdersReset} = driverOrdersSlice.actions
export const {hostEarningsReset} = driverEarningsSlice.actions
export const {hostPendingEarningsReset} = driverPendingEarningsSlice.actions

export { allDriversSlice, driverDetailSlice, driverOrdersSlice, driverDeleteSlice, driverEarningsSlice, driverPendingEarningsSlice }