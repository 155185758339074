import React, { useState } from 'react';
import DangerButton from './DangerButton';
import DialogModal from './DialogModal';
import SecondaryButton from './SecondaryButton';
import request from '../utils/request';
import { useNavigate } from 'react-router-dom';

export default function ChangeDeliveryFee({ entry, setMessage }) {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [fee, setFee] = useState(entry.driverPay ?? entry.tip + entry.driverFee);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (!fee && fee !== 0) {
            return;
        }

        setLoading(true)

        await request.post('admin/driver-fee', {
            oid: entry._id,
            driverPay: fee
        });

        setLoading(false);
        setOpen(false);
        navigate('/orderList');
        setMessage('Delivery charges have been updated.');
    }

    return (
        <div>
            <div 
                className='mr-2 px-2 md:px-4 h-[30px] flex items-center cursor-pointer justify-center border border-gray-400 rounded-md whitespace-nowrap overflow-hidden text-ellipsis' 
                onClick={() => setOpen(true)}
            >
                Edit Driver Pay
            </div>
            <DialogModal openModal={open} setOpenModal={setOpen}>
                <DialogModal.Content title={'Edit Delivery Charges'}>
                    <form className="flex justify-between rounded">
                        <div className='w-full'>
                            <div className="mb-4 ml-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="total_delivery_charges">
                                    Total Delivery Charges
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="total_delivery_charges"
                                    type="number"
                                    placeholder="Enter Delivery Charges"
                                    name="total_delivery_charges"
                                    disabled
                                    value={(entry.tip + entry.driverFee).toFixed(2)}
                                />
                            </div>
                            <div className="mb-4 ml-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="new_delivery_charges">
                                    New Delivery Charges
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="new_delivery_charges"
                                    type="number"
                                    placeholder="Enter Delivery Charges"
                                    name="new_delivery_charges"
                                    value={fee.toFixed(2)}
                                    onChange={(e) => {
                                        const value = parseFloat(e.target.value);
                                        setFee(!isNaN(value) ? value : 0);
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </DialogModal.Content>
                <DialogModal.Footer>
                    <SecondaryButton className='p-2 border-blue text-blue' onClick={handleSubmit}>{loading ? 'Saving...' : 'Save'}</SecondaryButton>
                    <DangerButton className='p-2 border-red text-red' onClick={() => setOpen(false)}>Cancel</DangerButton>
                </DialogModal.Footer>
            </DialogModal>
        </div>
    );
}
