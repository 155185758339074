import React from 'react'
import { useEffect, useRef, useState } from "react";
import request from "../../utils/request";
import imagePlacholderIcon from "../../icons/imagePlaceholder.svg";
import { CircularProgress } from "@mui/material";

// setError --> coming from parent component

function CreatePackageForm({
  editPackage,
  isEditMode,
  setIsModalOpen,
  setMessage,
  setError,
  step,
  setStep,
  selectedProducts,
  setSelectedProducts,
  setSuccess
}) {

  const fileInputFront = useRef(null);
  // useStates ------------------
  const [packageName, setPackageName] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const [packageDescription, setPackageDescription] = useState("");
  const [image, setImage] = useState();
  const [, setSearch] = useState("");
  const [, setIsPackageComplete] = useState(false);
  const [imageUrlPreview, setImageUrlPreview] = useState();
  const [loadingCreate, setLoadingCreate] = useState(false);

  const resetPackageDetails = () => {
    setPackageName("");
    setPackagePrice("");
    setPackageDescription("");
    setImage();
    setStep(1);
    setSearch("");
    setSelectedProducts([]);
    setIsPackageComplete(false);
    setImageUrlPreview();
  };


  const handleOnDragOver = (event) => {
    event.preventDefault();
  };

  const handleFile = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files[0];
    console.log("1");

    setImage(file);
    setImageUrlPreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleOnDrop = (event) => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();

    handleFile(event);
  };


  const handleCreate = async (e) => {
    e.preventDefault();

    if (!image) {
      setError("Please select an image");
      return;
    } else if (!packageName) {
      setError("Please enter a package name");
      return;
    }

    // setting the loader
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append("name", packageName);
    // formData.append("price", packagePrice); //price khud hojati calculate guest ecommerce pe while fetching from kroger
    formData.append("description", packageDescription);
    formData.append("image", image);
    selectedProducts.forEach((product) => {
      formData.append("products", product.productId);
    });


    // Sending API Request
    try {
      const { data } = await request.post("/packages", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (data.success) {
        setIsModalOpen(false);
        resetPackageDetails();
        setLoadingCreate(false);
        setMessage("Package Added Successfully");
        setSuccess(true)
      }
    } catch (err) {
      console.log(err);
      setLoadingCreate(false);
      setError(err.response.data.error);
    }
  }

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!image && !isEditMode) {
      setError("Please select an image");
      return;
    } else if (!packageName) {
      setError("Please enter a package name");
      return;
    }

    // setting the loader
    setLoadingCreate(true);

    const formData = new FormData();
    formData.append("name", packageName);
    formData.append("description", packageDescription);
    image && formData.append("image", image);
    selectedProducts.forEach((product) => {
      formData.append("products", product.productId);
    });


    // Sending API Request
    try {
      console.log("Inside API call");
      const { data } = await request.patch(`/packages/${editPackage.packageId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (data.success) {
        setIsModalOpen(false);
        resetPackageDetails();
        setLoadingCreate(false);
        setMessage("Package Updated Successfully");
        setSuccess(true)
        console.log("hey you");
      }
    } catch (err) {
      console.log(err);
      setLoadingCreate(false);
      setError(err.response.data.error);
    }
  }

  useEffect(() => {
    let total = 0;
    selectedProducts.forEach((product) => {
      //For krogger product 
      if (product.items) {
        total += Number(product.items[0].price.regular);
      }
      // For our own local products
      else {
        total += Number(product.price)
      }

    });
    setPackagePrice(total.toFixed(2));
  }, [selectedProducts]);

  useEffect(() => {
    if (editPackage) {
      setPackageName(editPackage.name);
      setPackageDescription(editPackage.description);
      setImageUrlPreview(editPackage.image);
    }
  }, []);

  return (
    <div>
      <div>
        <div>
          <div class="grid grid-cols-2 gap-5">
            <div className="my-4">
              <label
                htmlFor="packageName"
                className="text-[18px] font-segoe font-semibold"
              >
                Package Name
              </label>
              <input
                name="packageName"
                type="text"
                id="packageName"
                className="border-[#D9D9D9] border-[1px] rounded-[12px] my-2 w-full outline-none p-2 text-base1"
                value={packageName}
                onChange={(e) => setPackageName(e.target.value)}
              />
            </div>
            <div className="my-4">
              <label
                htmlFor="packagePrice"
                className="text-[18px] font-segoe font-semibold"
              >
                Package Price
              </label>
              <input
                name="packagePrice"
                type="text"
                id="packagePrice"
                className="border-[#D9D9D9] border-[1px] rounded-[12px] my-2 w-full outline-none p-2 px-4 text-base1"
                value={packagePrice}
                // value={`$ ${packagePrice}`}
                readOnly
              />
            </div>
          </div>

          <div>
            <div>
              <label
                htmlFor="packageDescription"
                className="text-[18px] font-segoe font-semibold "
              >
                Package Description
              </label>
            </div>
            <textarea
              name="packageDescription"
              className="border-[#D9D9D9] border-[1px] rounded-[12px] my-2 w-full outline-none p-2 text-base1"
              id="packageDescription"
              value={packageDescription}
              onChange={(e) => setPackageDescription(e.target.value)}
            />
          </div>

          <div className="w-full h-fit bg-lightgrey flex justify-center items-center">
            <div
              className="drop_zone w-full"
              onDragOver={handleOnDragOver}
              onDrop={handleOnDrop}
              id="front"
              onClick={() => fileInputFront.current.click()}
            >
              <button className="p-2 py-8 bg-lightgrey text-[#898989] rounded-lg m-auto w-full flex flex-col gap-4 justify-center items-center min-h-[150px]">
                {imageUrlPreview ? (
                  <img src={imageUrlPreview} alt="pkg-image" />
                  // <img src={imageUrlPreview} alt="package-image" />
                ) : (
                  <>
                    <img src={imagePlacholderIcon} className="flex justify-center max-h-[100px] w-fit m-auto" alt="" />
                    <p className="underline">Upload Front Side</p>
                  </>
                )}
              </button>
              <input
                id="frontInput"
                className="bg-black"
                type="file"
                accept=".png, .jpg, .jpeg"
                ref={fileInputFront}
                hidden
                onChange={(e) => handleFile(e)}
              />
            </div>
          </div>

          <button
            className="bg-[#EF4949] rounded-[50px] w-full my-4 h-[50px] text-white"
            onClick={(e) => {
              isEditMode ? handleUpdate(e) : handleCreate(e)
              // handleCreate(e)
            }
            }
          >
            {loadingCreate ? (
              <CircularProgress
                color="inherit"
                className="mr-2"
                size={"20px"}
              />
            ) : (
              <p className="text-[white] py-3 px-7">{isEditMode ? "Update" : "Create"}</p>
            )}
          </button>
        </div>
        <button onClick={() => setStep(step - 1)}>Back</button>
      </div>
    </div>
  )
}

export default CreatePackageForm