import React from 'react'
import { NavLink } from 'react-router-dom'
import copyIcon from '../../icons/copy.svg'
import { useDispatch } from 'react-redux'
import { deleteProperty } from '../../store/thunks/propertyActions'

const HostPropertiesTable = ({ data }) => {

    const dispatch = useDispatch()

    // Handlers
    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this property?")) {
            dispatch(deleteProperty(id))
        }
    }

    function handleCopyBtn(content) {
        navigator.clipboard.writeText(content);
            // copyToClipboard(content)
      }

    return (
        <div className="relative overflow-x-auto border-[0.5px] border-solid border-[#CCCCCC] rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-[black] text-[white]">
                    <tr>
                        <th className="px-4 py-3">
                            Description
                        </th>
                        <th className="px-4 py-3">
                            Address
                        </th>
                        <th className="px-4 py-3">
                            Unique Link
                        </th>
                        <th className="px-4 py-4">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((entry, index) => (
                        <tr
                            key={entry._id}
                            className="bg-white border-b-[#CCCCCC] border-b-[0.5px]"
                        >
                            <td className="px-4 w-fit max-w-[200px]">
                                <p className='truncate'>{entry.description}</p>
                            </td>

                            <td className="px-4 w-fit max-w-[200px]">
                                <p className='truncate'>{`${entry.address.line1} ${entry?.address.line2 ? entry?.address.line2 : ''} ${entry.address.city} ${entry.address.state}`}</p>
                            </td>

                            <td className="px-4 w-fit min-w-[190px]">
                                <div className="flex justify-between max-w-[200px]">
                                    <p>Copy Unique Link</p>
                                    <img src={copyIcon} className=' cursor-pointer' alt="copy" onClick={() => handleCopyBtn(entry.uniqueLink)} />
                                </div>
                            </td>

                            <td className="py-4 px-4 flex justify-between w-fit gap-2">
                                <NavLink to={`/hostList/${entry.hostID}/${entry._id}`} className="bg-viewBlue p-1 px-4 rounded-[21px] text-[white]">
                                    View
                                </NavLink>
                                <NavLink to={`/hostList/${entry.hostID}/${entry._id}?edit=true`} className="bg-editGreen p-1 px-4 rounded-[21px] text-[white]">
                                    Edit
                                </NavLink>
                                <NavLink onClick={() => handleDelete(entry._id)} className="bg-deleteRed p-1 px-4 rounded-[21px] text-[white]">
                                    Delete
                                </NavLink>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default HostPropertiesTable