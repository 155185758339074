import React, { useEffect, useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { DriverPromotionService } from '../services/DriverPromotionService';
import DriverPromotionForm from '../components/DriverPromotionForm';

const DriverPromotionEdit = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { pid } = params;


    useEffect(() => {
        if (pid) {
            fetchOffer(pid);
        } else {
            navigate('/driver-promotions');
        }
    }, [])
    const [message, setMessage] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);


    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        bonusAmount: 0,
        targetOrder: 0
    });

    const fetchOffer = async (pid) => {
        setLoading(true)
        const response = await DriverPromotionService.getDriverPromotion(pid);
        setFormData({
            startDate: response.startDate,
            endDate: response.endDate,
            bonusAmount: response.bonusAmount,
            targetOrder: response.targetOrder
        })
        setLoading(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)
        if (
            !formData.startDate
            || !formData.endDate
            || !formData.bonusAmount
            || !formData.targetOrder
        ) {
            setError('All fields are required');
            setLoading(false)
            return;
        }

        try {
            await DriverPromotionService.updateDriverPromotion(pid, formData)
            setMessage('Promotion has been updated');
            setLoading(true)
            setTimeout(() => {
                navigate('/driver-promotions');
            }, 1500);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-10 bg-white">
                    <AdminHeader
                        heading={"Edit Driver Promotion"}
                        pages={"Pages / Offers / Edit Driver Promotion"}
                    />
                </div>
                <div className="px-6 py-5 h-full overflow-auto ">
                    <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 min-h-[60%] mt-1" style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {
                            loading ? <CircularProgress /> : (
                                <DriverPromotionForm
                                    message={message}
                                    handleSubmit={handleSubmit}
                                    formData={formData}
                                    setFormData={setFormData}
                                    error={error}
                                />)
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DriverPromotionEdit