import React, { useEffect, useState } from 'react'
import InputBox from '../utilityComponents/InputBox'
import ProductCards from '../ProductCards'
import backIcon from '../../icons/left.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getDateTime } from '../../utils/getDateTime';

const OrderDetailsComponent = ({ order }) => {

    const navigate = useNavigate()
    const [queryParam] = useSearchParams();

    const [propertyAddress, setPropertyAddress] = useState('');
    const [details, setDetails] = useState({
        _id: "", status: "", propertyID: "", bellhoptFee: "", driverFee: "", hostFee: "", groceryFee: "", products: "", inStockDate: "", storeLocation: "", property: { address: "" }
    });

    const [deliveryTime, setDeliveryTime] = useState();
    const [orderAmount, setOrderAmount] = useState();

    useEffect(() => {

        setDetails({
            _id: order._id,
            status: order.status,
            tip: order.tip,
            propertyID: order.propertyID,
            bellhoptFee: order.bellhoptFee,
            driverFee: order.driverFee,
            hostFee: order.hostFee,
            groceryFee: order.groceryFee,
            products: order.products,
            inStockDate: order.inStockDate,
            storeLocation: order.storeLocation,
            address: order.property.address
        })
    }, [order])

    useEffect(() => {
        setOrderAmount(details.bellhoptFee + details.driverFee + details.hostFee + details.groceryFee);
        let time = getDateTime(details.inStockDate);
        setDeliveryTime(time);
        setPropertyAddress(`${details.address?.line1} ${details.address?.line2 ? details.address?.line2 : ''} ${details.address?.city} ${details.address?.zipCode} ${details.address?.state}`)
    }, [details])



    return (
        <div className="px-6 py-5 h-screen overflow-auto">
            <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 overflow-scroll">
                <div className="w-[95%] m-auto py-5">
                    <div className=" border-b-lightborder border-b-[0.5px] w-full py-4">

                        <div className="flex justify-between items-center w-fit">
                            <img src={backIcon} className='w-5 mr-3 cursor-pointer'
                                onClick={() => {
                                    if (queryParam.get("order") === 'true') {
                                        navigate(`/orderList?status=${order.status}`)
                                    }
                                    else { navigate(-1) }
                                }} alt="" />
                            <h2 className='font-bold text-base3'>{order._id}</h2>
                            {/* <p className="ml-5 text-center inline-block w-fit bg-viewBlue py-2 px-5 rounded-[21px] text-white text-base1 font-semibold"> */}
                            <p className={`text-center w-[100px] ${details.status === "completed" ? 'bg-submitGreen': details.status === "picked" || "pending" || "requested" ? 'bg-[#F6BE00]': 'bg-deleteRed'} py-2 px-3 rounded-[21px] text-white`}>

                                {details.status === 'picked' ? 'picked up' : details.status}
                            </p>
                        </div>





                        {/* <td className="py-4 px-4 w-fit flex">
                                    <p className={`text-center w-[100px] ${order.status === "completed" ? 'bg-submitGreen': order.status === "picked" || "pending" || "requested" ? 'bg-[#F6BE00]': 'bg-deleteRed'} py-2 px-3 rounded-[21px] text-white`}>
                                        {order.status==='picked'? "picked up": order.status}
                                    </p>
                                </td> */}







                    </div>

                    <div className="mb-5 border-b-[0.5px] border-[grey] pb-8">
                        <h1 className="text-base3 font-segoe font-semibold flex items-center gap-x-2 my-4">
                            Order Details
                        </h1>

                        {
                            order && (
                                <div className="grid grid-cols-3 gap-4">


                                    <InputBox name="Ordered By" value={order?.guestEmail} />
                                    <InputBox name="Ordered Amount" value={orderAmount && orderAmount?.toFixed(2)} />
                                    <InputBox name="Total Driver Earnings" value={((details?.tip || 0) + (details?.driverFee || 0)).toFixed(2)} />
                                    <InputBox name="Driver's Tip" value={details?.tip} />
                                    <InputBox name="Driver's Earning" value={details?.driverFee} />
                                    <InputBox name="Host's Earning" value={details?.hostFee} />
                                    <InputBox name="Bellhopt's Earning" value={details?.bellhoptFee} />
                                </div>
                            )
                        }
                    </div>



                    <div className="mb-5 border-b-[0.5px] border-[grey] pb-8">
                        <h1 className="text-base3 font-segoe font-semibold flex items-center gap-x-2 mt-4 pb-7">
                            Product Details
                        </h1>

                        <div className="grid gap-4 laptop:grid-cols-2 desktop:grid-cols-3">
                            {
                                details.products.length > 0 && details.products.map((product) => (

                                    // <ProductCards />
                                    <ProductCards product={product} />
                                ))
                            }
                        </div>
                    </div>



                    <div className="mb-5 border-b-[0.5px] border-[grey] pb-10">
                        <h1 className="text-base3 font-segoe font-semibold flex items-center gap-x-2 mt-4 pb-7">
                            Delivery Details
                        </h1>

                        <div className="grid grid-cols-2 gap-4">
                            <InputBox name="Pickup Location" value={details?.storeLocation} />
                            <InputBox name="Delivery Address" value={propertyAddress} />
                        </div>
                    </div>


                    <div>
                        <h3 className='font-bold'>Estimated Delivery Time: <p className='text-light inline-block font-semibold ml-5'>{`${deliveryTime?.Date} At ${deliveryTime?.Time}`}</p> </h3>
                    </div>




                </div>
            </div>
        </div>
    )
}

export default OrderDetailsComponent