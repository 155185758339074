import React from 'react'
import { NavLink } from 'react-router-dom'
import copyIcon from '../../icons/copyIcon.png'
import arrow from '../../icons/viewArrowIcon.svg'

const DriverOrdersTable = ({ data }) => {

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    };

    return (
        <div className="relative overflow-x-auto border-[0.5px] border-solid border-[#CCCCCC] rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-[black] text-[white]">
                    <tr>
                        <th className="px-4 py-3">
                            Ordered By
                        </th>
                        <th className="px-4 py-3">
                            Delivery Address
                        </th>
                        <th className="px-4 py-3">
                            Delivery Date
                        </th>
                        <th className="px-4 py-3">
                            Delivery Fee
                        </th>
                        <th className="px-4 py-3">
                            Tip
                        </th>
                        <th className="px-4 py-3">
                            {/* Status */}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((entry, index) => (
                        <tr
                            key={entry._id}
                            className="bg-white border-b-[#CCCCCC] border-b-[0.5px] py-4"
                        >
                            <td className="px-4 w-fit max-w-[170px]">
                                <p className='truncate'>{entry.propertyID}</p>
                            </td>

                            <td className="px-4 w-fit max-w-[200px]">
                                <p className='truncate'>{`${entry?.property?.address.line1} ${entry?.property?.address.line2 ? entry?.property?.address.line2 : ''} ${entry?.property?.address.city} ${entry?.property?.address.state}`}</p>
                            </td>

                            <td className="px-4 w-fit min-w-[120px]">
                                <p className='truncate'>{new Date(entry.inStockDate).toLocaleString('en-US', options)}</p>
                            </td>

                            <td className="px-4 w-fit min-w-[50px]">
                                <p className='truncate'>$14.99</p>
                            </td>

                            <td className="px-4 w-fit min-w-[50px]">
                                <p className='truncate'>${entry.tip}</p>
                            </td>

                            <td className="py-4 px-4 w-fit flex ml-auto">
                                <p className={`text-center w-[100px] ${entry.status === "completed" ? 'bg-editGreen': entry.status === "picked" || "pending" || "requested" ? 'bg-viewBlue': 'bg-deleteRed'} py-2 px-3 rounded-[21px] text-[white]`}>
                                    {entry?.status}
                                </p>
                                <NavLink to={`/orderList/${entry._id}`} className="p-1 px-4 rounded-[21px] flex gap-2">
                                    <p>View</p>
                                    <img src={arrow} alt="" />
                                </NavLink>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default DriverOrdersTable