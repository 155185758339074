import React, { useEffect, useState } from "react";
import request from "../../utils/request";
import { useParams, useSearchParams } from "react-router-dom";
import InputBoxEditable from "../utilityComponents/InputBoxEditable";
import automationVector from "../../icons/automationVector.svg";

const ParserDetailsComponent = ({
  success,
  setSuccessMessage,
  setErrorMessage,
}) => {
  const param = useParams();
  const [query] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const [automationCredentials, setAutomationCredentials] = useState({
    parserName: "",
    parserAddress: "",
    guestName: "",
    guestEmail: "",
    emailSubject: "",
    arrivalTime: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAutomationCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // ----------- Handlers for CRUD -------------

  const getParser = async () => {
    setLoading(true);
    try {
      const { data } = await request.get(`/parsers/parser/${param.prid}`);
      if (data.success) {
        setAutomationCredentials(data.parser);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.error);
      console.log(error);
    }
    setLoading(false);
  };

  const createParser = async () => {
    const { parserName, emailSubject } =
      automationCredentials;
    if (
      !parserName ||
      !emailSubject
    ) {
      setErrorMessage("Please fill all the fields");
      return;
    }

    // calling API
    setLoading(true);
    try {
      const { data } = await request.post(
        "/parsers/create-parser",
        automationCredentials
      );

      if (data.success) {
        setSuccessMessage("Parser created successfully");
      }
    } catch (error) {
      setErrorMessage("Something went wrong");
      console.log(error.response.data.error);
    }

    setLoading(false);
  };

  const updateparser = async () => {
    const { parserName, emailSubject } =
      automationCredentials;
    if (
      !parserName ||
      !emailSubject
    ) {
      setErrorMessage("Please fill all the fields");
      return;
    }

    // calling API
    setLoading(true);
    try {
      const { data } = await request.patch(
        `/parsers/parser/${param.prid}`,
        automationCredentials
      );

      if (data.success) {
        setSuccessMessage("Parser updated successfully");
      }
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.log(error.response.data.error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (query.get("new") === "false") {
      getParser();
    }
  }, []);

  return (
    <div className="bg-[white] p-5">
      <div className="flex flex-col mb-5 py-3">
        <header className=" font-segoe flex gap-3">
          <div className="flex gap-3 items-center">
            <img src={automationVector} alt="automationVector" className="" />
            <h2 className="text-[24px] font-bold">Email Automation</h2>
          </div>
        </header>

        {loading ? (
          <div className="w-full py-5 h-30 rounded bg-primar flex flex-col gap-8">
            <div className="w-full h-10 rounded bg-lightgrey"></div>
            <div className="w-full h-10 rounded bg-lightgrey"></div>
            <div className="w-full h-10 rounded bg-lightgrey"></div>
          </div>
        ) : (
          <div className="flex flex-col gap-8 justify-between py-5">
            <div className="flex gap-5">
              <InputBoxEditable
                name="parserName"
                label={"Parser Name"}
                value={automationCredentials?.parserName}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="flex gap-5">
              <InputBoxEditable
                name="emailSubject"
                label={"Email Subject"}
                value={automationCredentials?.emailSubject}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        )}

        <button
          onClick={() => {
            query.get("new") === "false" ? updateparser() : createParser();
          }}
          className="bg-white hover:bg-black hover:text-white border-2 border-black text-black py-1 px-2 rounded ml-auto font-medium"
        >
          {query.get("new") === "false" ? "Update" : "Create"}
        </button>
      </div>
    </div>
  );
};

export default ParserDetailsComponent;
