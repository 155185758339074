import React, { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { Alert } from '@mui/material';
import { TimeAndDateFormat } from '../utils/TimeAndDateFormat';

const DriverPromotionForm = ({ message, handleSubmit, formData, setFormData, error }) => {

    const startDate = TimeAndDateFormat.getFormattedDate(formData.startDate) ? TimeAndDateFormat.getFormattedDate(formData.startDate) : ''
    const endDate = TimeAndDateFormat.getFormattedDate(formData.endDate) ? TimeAndDateFormat.getFormattedDate(formData.endDate) : ''

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    return (
        <>
            {message &&
                <Alert
                    severity="success"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 20,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {message}
                </Alert>
            }
            {error &&
                <Alert
                    severity="error"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 20,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {error}
                </Alert>
            }
            <div className="px-6 py-5 h-full overflow-auto w-full">
                <form onSubmit={handleSubmit} className="flex bg-white justify-between rounded px-8 pt-6 pb-8 mb-4">
                    <div className='w-1/2'>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startDate">
                                Start Date
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="startDate"
                                type="date"
                                placeholder="Enter start date"
                                name="startDate"
                                value={startDate}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4 ml-2" >
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="link">
                                Bonus Amount
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="link"
                                type="number"
                                placeholder="Enter Bonus Amount"
                                name="bonusAmount"
                                value={formData.bonusAmount}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4 ml-2" >
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="targetOrder">
                                Target Order
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="targetOrder"
                                type="number"
                                placeholder="Enter Target Order"
                                name="targetOrder"
                                value={formData.targetOrder}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endDate">
                                End Date
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="endDate"
                                type="date"
                                placeholder="Enter End date"
                                name="endDate"
                                value={endDate}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex items-center justify-end mt-10">
                            <button
                                className="bg-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}

export default DriverPromotionForm