import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPackagesKroger } from "../../utils/packages.js";
import request from "../../utils/request.js";

const getPackages = createAsyncThunk(
    "packages/getPackages",
    async (id, { rejectWithValue }) => {

        try {
            // getting saved packages from the DB
            const { data } = await request.get('/packages')

            // Passing the product Ids from the packages to kroger in order to fetch details
            const packages = getPackagesKroger(data.packages)

            return packages;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const updatePackageStatus = createAsyncThunk(
    "updatePackage/updatePackageStatus",
    async (params, {rejectWithValue}) =>{

        try {
            const {data} = await request.patch(`/packages/${params.id}`, {isActive: params.status})

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export {getPackages, updatePackageStatus}