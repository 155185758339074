import { Alert } from '@mui/material'
import React from 'react'
import ReactQuill from 'react-quill'
import { TimeAndDateFormat } from '../utils/TimeAndDateFormat';

const OffersForm = ({ message, handleSubmit, formData, setFormData, loading, error }) => {

    console.log('formData => ', formData);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const { name } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: file
        }));
    };

    const handleCheckBox = (e) => {
        const isChecked = e.target.checked;
        setFormData((prevData) => ({
            ...prevData,
            showForm: isChecked
        }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const isURL = (str) => {
        const urlPattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        );
        return urlPattern.test(str);
    }

    return (
        <>
            {message &&
                <Alert
                    severity="success"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 20,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {message}
                </Alert>
            }

            {error &&
                <Alert
                    severity="error"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 20,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {error}
                </Alert>
            }
            <div className="px-6 py-5 h-full overflow-auto w-full">
                <form onSubmit={handleSubmit} className="flex justify-between rounded px-8 pt-6 pb-8 mb-4" encType='multipart/formdata'>
                    <div className='w-1/2'>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
                                Title
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="title"
                                type="text"
                                placeholder="Enter Title"
                                name="title"
                                value={formData?.title}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="afterProduct">
                                After Product
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="afterProduct"
                                type="number"
                                placeholder="Enter after product"
                                name="afterProduct"
                                value={formData?.afterProduct}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                                Description
                            </label>
                            <ReactQuill
                                theme="snow"
                                name=""

                                value={formData?.description}
                                onChange={(value) => setFormData({ ...formData, description: value })}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        ['link', 'image', 'video'],
                                        ['clean']
                                    ]
                                }}
                            />
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expireAt">
                                Expire At
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="expireAt"
                                type="date"
                                placeholder="Enter expire at"
                                name="expireAt"
                                value={TimeAndDateFormat.getFormattedDate(formData.expireAt) ? TimeAndDateFormat.getFormattedDate(formData.expireAt) : ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bannerImage">
                                Banner Image
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="bannerImage"
                                type="file"
                                accept="image/*"
                                name="bannerImage"
                                onChange={handleFileChange}
                            />
                            {
                                isURL(formData.bannerImage) &&
                                <img src={formData.bannerImage} style={{ height: '70px', width: '70px' }} className='mt-4' />
                            }
                        </div>
                        <div className="mb-4 ml-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="logo">
                                Logo
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="logo"
                                type="file"
                                accept="image/*"
                                name="logo"
                                onChange={handleFileChange}
                            />
                            {
                                isURL(formData.bannerImage) &&
                                <img src={formData.bannerImage} style={{ height: '70px', width: '70px' }} className='mt-4' />
                            }
                        </div>
                        <div className="flex items-center m-10">
                            <label className="block text-gray-700 text-sm font-bold mb-2 mr-4" htmlFor="logo">
                                Show Form?
                            </label>
                            <input
                                id="logo"
                                type="checkbox"
                                placeholder="Enter after product"
                                name="logo"
                                checked={formData?.showForm}
                                onChange={(e) => handleCheckBox(e)}
                            />
                        </div>
                        <div className="flex items-center justify-end mt-10">
                            <button
                                className="bg-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default OffersForm