import axios from "axios";
import request from "./request";


const getKrogerStoreLocation = async (token, lat, lng) => {

  const { data } = await axios.get(`https://api.kroger.com/v1/locations?filter.latLong.near=${lat},${lng}&filter.radiusInMiles=100&filter.limit=10`, {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Access-Control-Allow-Credentials': true
    },
    crossDomain: true,
  });

  localStorage.setItem("locationId", JSON.stringify(data.data[0].locationId));
 
  // storing the Store Location 
  localStorage.setItem("storeLocation", JSON.stringify(`${data.data[0].address.addressLine1}, ${data.data[0].address.city}`));

  // returning the location Id of Kroger Shop
  return data.data[0].locationId;
}

const getProductsKroger = async (token, term = 'Grocery', start = 1, limit = 12) => {

  const locationId = localStorage.getItem("locationId") && JSON.parse(localStorage.getItem("locationId"));

  const { data } = await axios.get(`https://api.kroger.com/v1/products?filter.term=${term}&filter.start=${start}&filter.limit=${limit}&filter.fulfillment=ais&filter.locationId=${locationId}`, {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Access-Control-Allow-Credentials': true,
      withCredentials: false,
    },
    crossDomain: true,
  });

  return data.data;
}
const getProductDetailsKroger = async (id, token) => {

  const { data } = await axios.get(`https://api.kroger.com/v1/products/${id}?filter.locationId=${70300022}`, {
    headers: {
      'Authorization': 'Bearer ' + token,
      'Access-Control-Allow-Credentials': true
    },
    crossDomain: true,
  });

  return data.data;
}

const getGuestProperty = async (pid) => {

  try {
    const { data } = await request.get(`/properties/guest/${pid}`);
    localStorage.setItem("property", JSON.stringify(data));

    // console.log("getGuestProperty", data);
    return data._id;

  } catch (err) {
    console.log(err);
  }
}

export {getProductsKroger, getProductDetailsKroger, getGuestProperty, getKrogerStoreLocation }