import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import request from "../utils/request";
import { CircularProgress } from "@mui/material";
import { deletePackage } from "../store/slices/packageSlices";

function PackageCard({
  pkg,
  setIsEditModalOpen,
  setEditPkgId,
  setSuccessDelete,
  setMessage,
  _setError,
}) {
  const dispatch = useDispatch();

  const { products, isActive } = pkg;
  const [isChecked, setIsChecked] = useState(pkg.isActive);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [price, setPrice] = useState(
    products
      .reduce((acc, product) => {
        if (product.items) {
          return acc + product.items[0].price?.regular;
        } else {
          return acc + product.price;
        }
      }, 0)
      .toFixed(2)
  );

  const updateStatus = async () => {
    setSuccess(false);
    setToggleLoading(true);
    try {
      const { data } = await request.patch(`/packages/${pkg.packageId}`, {
        isActive: !isChecked,
      });
      if (data.success) {
        setSuccess(true);
        setToggleLoading(false);
        setIsChecked(!isChecked);
      }
    } catch (error) {
      setToggleLoading(false);
      setSuccess(false);
      console.log("error", error);
    }
  };

  const handleDelete = async () => {

    if (!window.confirm("Are you sure you want to delete this Package?")) {
      return
    }
    try {
      // Make the DELETE request to delete the package
      setLoadingDelete(true);
      const response = await request.delete(`/packages/${pkg.packageId}`);

      // Handle the response as needed
      if (response.status === 200) {
        setLoadingDelete(false);
        setSuccessDelete(true);
        dispatch(deletePackage(pkg.packageId))
        console.log("Package deleted successfully");
        setMessage("Package deleted successfully");
      } else {
        setLoadingDelete(false);
        console.error("Failed to delete package");
        _setError("Failed to delete package");
      }
    } catch (error) {
      setLoadingDelete(false);
      _setError(error.response.data.message);
      console.error("An error occurred while deleting the package:", error);
    }
  };

  return (
    <div className="card border-[0.5px] border-lightborder rounded-[12px] px-5 py-3 relative">
      {/* Delete Button */}
      <button
        className="group absolute right-[-5px] top-[-8px] rounded-xl bg-red text-white h-7 w-7 hover:w-full transition-all duration-300"
        onClick={() => handleDelete()}
      >
        <span className="group-hover:hidden text-base font-bold">x</span>
        <span className="hidden group-hover:block font-bold text-md">
          {loadingDelete == true ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            "Delete Package"
          )}
        </span>
      </button>
      {/* --------------------------------------- */}

      <div className="flex flex-col h-full min-h-[250px]">
        <div className="h-[120px]">
          <img
            className="flex justify-center max-h-[100px] w-fit m-auto"
            src={pkg.image}
            alt="pkg-image"
          />
        </div>

        {/* <h1 className="text-[24px] text-light">{pkg.name}</h1> */}
        <h1 className="text-[24px] font-bold font-segoe">${price}</h1>
        <div className="mb-3">
          {products &&
            products.map((prod, index) => (
              <h3
                key={index}
                className="text-base1 font-segoe p-[1px] truncate"
              >
                {prod.description}
              </h3>
            ))}
        </div>

        {/* -------------------------------------------------- */}

        <div className="flex justify-between mt-auto">
          <label className="relative flex items-center cursor-pointer">
            <input
              type="checkbox"
              value={isChecked}
              onChange={updateStatus}
              class="sr-only peer"
            />
            <div
              className={`w-11 h-6 ${isChecked ? "bg-primary" : "bg-lightborder"
                } peer-focus:outline-none rounded-full ${isChecked ? "after:translate-x-full" : ""
                } peer-checked:after:border-white after:content-[''] after:absolute after:bg-white after:rounded-full after:h-5 after:top-[2px] after:left-[2px] after:w-5 after:transition-all dark:border-gray-600`}
            ></div>

            {!toggleLoading ? (
              <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                {isChecked ? "Active" : "InActive"}
              </span>
            ) : (
              <CircularProgress
                color="inherit"
                className="ml-4"
                size={"20px"}
              />
            )}
          </label>
          <button
            className=" hover:text-primary"
            onClick={() => {
              setIsEditModalOpen(true);
              setEditPkgId(pkg.packageId);
            }}
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
}

export default PackageCard;
