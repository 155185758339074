import React from 'react';
import Select from 'react-select';

export default function SelectDropdown({value, options,isMulti=false, onChange}) {
    const customStyles = {
        control: (provided) => ({
            ...provided,

            ':hover': {
                borderColor: '#8a49e2',
            },
            ':focus': {
                borderColor: '#8a49e2',
                boxShadow: '0 0 0 1px #2684FF',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#8a49e2' : state.isFocused ? '#e8def9' : 'white',
            color: state.isSelected ? 'white' : '#333',
        }),
    };

    let updatedOptions;
    if(!isMulti) {
        const basicOption = {
            value: '',
            label: 'Please Select Any Option'
        };
    
        if (!options || !Array.isArray(options)) {
            updatedOptions = [basicOption];
        } else {
            updatedOptions = [basicOption, ...options];
        }
    } else {
        updatedOptions = [...options];
    }
    
    const customFilterOption = (option, rawInput) => {
        const words = rawInput.split(' ');
        return words.every(word =>
            option.label.toLowerCase().startsWith(word.toLowerCase())
        );
    };

    return (
        <>
            <Select
                value={value}
                isMulti={isMulti}
                options={updatedOptions}
                onChange={(value) => onChange(value)}
                styles={customStyles}
                filterOption={customFilterOption}
            />
        </>
    );
}
