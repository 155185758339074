import React from 'react'
import LoadingTableComponent from './utilityComponents/LoadingTableComponent'
import { NavLink } from 'react-router-dom'
import { TimeAndDateFormat } from '../utils/TimeAndDateFormat'
import DeletePromotion from './DeletePromotion'

const DriverPromotionsTable = ({ data, limit, loading, deleteRow }) => {

    return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-[black] text-[white]">
                <tr>
                    <th className="px-4 py-3">
                        Start Date
                    </th>
                    <th className="px-4 py-3">
                        End Date
                    </th>
                    <th className="px-4 py-3">
                        Bonus Amount
                    </th>
                    <th className="px-4 py-3">
                        Target Order
                    </th>
                    <th className="px-4 py-3">
                        Actions
                    </th>
                </tr>
            </thead>
            {loading ? (
                <LoadingTableComponent cols={5} />
            ) : (
                <tbody>
                    {
                        data?.length > 0 ? data.map((entry, index) => (
                            <tr
                                key={entry._id}
                                className="bg-white border-b-[#CCCCCC] border-b-[0.5px] py-4"
                            >
                                <td className="px-4 w-fit max-w-[170px]">
                                    {TimeAndDateFormat.getFormattedDate(entry.startDate)}
                                </td>
    
                                <td className="px-4 w-fit max-w-[200px]">
                                    {TimeAndDateFormat.getFormattedDate(entry.endDate)}
                                </td>
    
                                <td className="px-4 w-fit min-w-[120px]">
                                    {entry.bonusAmount}
                                </td>
    
                                <td className="px-4 w-fit min-w-[120px]">
                                    {entry.targetOrder}
                                </td>
                            
                                <td className="py-4 px-4 w-fit flex m-2">
                                    <NavLink to={`/driver-promotion/edit/${entry._id}`} className="p-1 px-4 rounded-[6px] flex gap-2 border border w-[60px]">
                                        <p>Edit</p>
                                    </NavLink>
                                    <DeletePromotion id={entry._id} deleteRow={deleteRow} />
                                </td>
                            </tr>
                        )) : (
                            <td className="text-center p-4 px-4 w-fit min-w-[50px]" colspan="7">
                                <p className="truncate">THERE IS NOT ANY PROMOTION AVAILABLE HERE.</p>
                            </td>
                        )
                    }
                </tbody>
            )}
        </table>
    )
}

export default DriverPromotionsTable