import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import nextIcon from "../../icons/next.svg";
import OwnProductContainer from "./OwnProductContainer";
import CreatePackageForm from "./CreatePackageForm";
import CreateItemForm from "./CreateItemForm";
import KrogerStoreComponent from "./KrogerStoreComponent";
import PackageSelectCard from "./PackageSelectCard";
import OwnPackageProduct from "./OwnPackageProduct";

const PackageModal = ({
    editPackage, //package with its id
    isModalOpen,
    setIsModalOpen,
    setMessage,
    setError,
    setSuccess,
    modalLabel,
    _error,
    _setError,
    isEditMode, // boolean
}) => {

    const [, setPackagePrice] = useState("");
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [step, setStep] = useState(1);
    const [isPackageComplete, setIsPackageComplete] = useState(false);
    const [activeTab, setActiveTab] = useState("ralphs"); // Default active tab is "ralphs"

    const handleNext = () => {
        if (step === 1) {
            // if (selectedProducts.length < 2) {
            //     _setError("Please select atleast two products");
            //     return;
            // }
            setStep(step + 1);
        }
    };

    useEffect(() => {
        let total = 0;
        selectedProducts.forEach((product) => {
            if (product.items) {
                total += Number(product.items[0].price.regular);
            } else {
                total += Number(product.price);
            }
        });
        setPackagePrice(total.toFixed(2));
    }, [selectedProducts]);

    useEffect(() => {
        setTimeout(() => {
            _setError();
        }, 2000);
    }, [_error]);


    useEffect(() => {
        if (editPackage?.products) {
            setSelectedProducts(editPackage.products);
        }
    }, [isModalOpen]);

    return (
        <ReactModal
            isOpen={isModalOpen}
            onRequestClose={() => {
                setIsModalOpen(false);
                setStep(1);
                setSelectedProducts([]);
                setIsPackageComplete(false);
                setActiveTab("ralphs");
            }}
            contentLabel={modalLabel}
            ariaHideApp={false}
            appElement={document.getElementById("root")}
            className="Modal"
            overlayClassName="Overlay"
        >
            <div>
                <h2 className="text-base3 font-segoe font-semibold">{modalLabel}  {isEditMode && <span> --  <span className="text-primary underline">{editPackage.name}</span></span> }</h2>

                {isEditMode && step == 1 && (
                    <div className="grid grid-cols-3 gap-5 mt-5">
                        {selectedProducts?.map((product) => {

                            if (product.upc)
                                return (
                                    <PackageSelectCard
                                        product={product}
                                        selectedProducts={selectedProducts}
                                        setSelectedProducts={setSelectedProducts}
                                        isEditMode={isEditMode}
                                    />
                                )
                            else
                                return (
                                    <OwnPackageProduct
                                        isEditMode={isEditMode}
                                        product={product}
                                        selectedProducts={selectedProducts}
                                        setSelectedProducts={setSelectedProducts}
                                        isPackageComplete={isPackageComplete}
                                        setIsPackageComplete={setIsPackageComplete}
                                    />
                                )
                        }
                        )}
                    </div>
                )
                }

                {step == 1 && (
                    <div className=" border-[1px] rounded-full my-[20px]">
                        <div className="p-1 flex justify-between ">
                            <button
                                className={`tab-button text-center ${activeTab === "ralphs" ? "active" : ""
                                    }`}
                                onClick={() => setActiveTab("ralphs")}
                            >
                                Products from Ralphs
                            </button>
                            <button
                                className={`tab-button text-center ${activeTab === "ownPackages" ? "active" : ""
                                    }`}
                                onClick={() => setActiveTab("ownPackages")}
                            >
                                My Own Products
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {activeTab === "ralphs" && (
                <>
                    {step === 1 && (
                        <KrogerStoreComponent
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            isPackageComplete={isPackageComplete}
                            setIsPackageComplete={setIsPackageComplete}
                            handleNext={handleNext}
                        />
                    )}
                </>
            )}

            {activeTab === "ownPackages" && (
                <>
                    {step === 1 && (
                        <div>
                            <div>
                                <OwnProductContainer
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                    isPackageComplete={isPackageComplete}
                                    setIsPackageComplete={setIsPackageComplete}
                                    setStep={setStep}
                                />
                            </div>

                            <div className="flex justify-end mt-5">
                                <button
                                    onClick={() => handleNext()}
                                // onClick={() => setStep(step + 1)}
                                >
                                    <div className="flex gap-2 items-center">
                                        <p className="text-[16px] font-segoe font-semibold text-[#EF4949]">
                                            Next
                                        </p>
                                        <img src={nextIcon} alt="next" />
                                    </div>
                                </button>
                            </div>
                        </div>
                    )}

                    {step === 3 && (
                        <CreateItemForm
                            setIsModalOpen={setIsModalOpen}
                            setMessage={setMessage}
                            isModalOpen={isModalOpen}
                            setError={setError}
                            setSuccess={setSuccess}
                            setStep={setStep}
                            step={step}
                        />
                    )}
                </>
            )}

            {step === 2 && (
                <CreatePackageForm
                    isEditMode={isEditMode}
                    editPackage={editPackage}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    setMessage={setMessage}
                    setError={setError}
                    setSuccess={setSuccess}
                    setStep={setStep}
                    step={step}
                    selectedProducts={selectedProducts}
                    handleNext={handleNext}
                    setSelectedProducts={setSelectedProducts}
                />
            )}
        </ReactModal>
    );
};

export default PackageModal;
