import React, { useRef, useState } from "react";
import { Alert } from "@mui/material";
import PackageModal from "./PackageModal";

const PackageCreateComponent = ({
  isModalOpen,
  setIsModalOpen,
  setMessage,
  setErrorCreate,
  setSuccessCreate,
  pkg,
  something
}) => {

  const [_error, _setError] = useState(false);

  return (
    <div>
      {_error && (
        <Alert
          severity="error"
          sx={{
            position: "fixed",
            width: "90%",
            margin: "auto",
            top: 20,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          {_error}
        </Alert>
      )}

      <PackageModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setMessage={setMessage}
        setError={setErrorCreate}
        setSuccess={setSuccessCreate}
        modalLabel="Create New Package"
        _error={_error}
        _setError={_setError}
      />
    </div>
  );
};

export default PackageCreateComponent;
