import React, { useState } from 'react';
import DangerButton from './DangerButton';
import DialogModal from './DialogModal';
import SecondaryButton from './SecondaryButton';
import request from '../utils/request';
import { useNavigate } from 'react-router-dom';
import SelectDropdown from './SelectDropdown';

export default function AssignDriver({ entry, setMessage, driverData, setError }) {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [driver, setDriver] = useState();

    const handleSubmit = async () => {
        if (!driver?.value) {
            return
        }

        try {
            const response = await request.patch(`orders/assign-driver/${entry._id}/${driver.value}`);
            setOpen(false)
            navigate('/orderList')
            setMessage('Driver has been assigned.');
        } catch (error) {
            setError(error)
            console.log(error);
        }
    }

    const driverOptions = driverData && driverData.map((driver, index) => {
        return {
            label: driver.name,
            value: driver.id
        }
    })

    return (
        <div>
            <div 
                className='mr-2 px-2 md:px-4 h-[30px] cursor-pointer flex items-center justify-center border border-gray-400 rounded-md whitespace-nowrap overflow-hidden text-ellipsis' 
                onClick={() => setOpen(true)}
            >
                Assign Driver
            </div>
            <DialogModal openModal={open} setOpenModal={setOpen}>
                <DialogModal.Content title={'Assign Driver'}>
                    <form className="flex justify-between rounded">
                        <div className='w-full'>
                            <div className="mb-4 ml-2">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="delivery_charges">
                                    Select Driver
                                </label>
                                <SelectDropdown
                                    value={driver}
                                    options={driverOptions}
                                    isMulti={false}
                                    onChange={option => setDriver(option)}
                                />
                            </div>
                        </div>
                    </form>
                </DialogModal.Content>
                <DialogModal.Footer>
                    <SecondaryButton onClick={handleSubmit}>Save</SecondaryButton>
                    <DangerButton
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </DangerButton>
                </DialogModal.Footer>
            </DialogModal>
        </div>
    );
}
