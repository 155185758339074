import request from "../../utils/request";
import { createAsyncThunk } from "@reduxjs/toolkit";

const getAllDrivers = createAsyncThunk(
    "allDrivers/getAllDrivers",
    async ({limit, status}, { rejectWithValue }) => {

        let url = `/drivers?status=${status}`
        if (limit){
            url = `/drivers?limit=${limit}&status=${status}`
        }
        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

const getDriverDetails = createAsyncThunk(
    "driverDetails/getDriverDetails",
    async (did, { rejectWithValue }) => {
        try {
            const { data } = await request.get(`/drivers/${did}`, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

const getDriverOrders = createAsyncThunk(
    "driverOrders/getDriverOrders",
    async (did, { rejectWithValue }) => {
        try {
            const { data } = await request.get(`/orders/driver/${did}`, {
                withCredentials: true,
            });
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const deleteDriverAction = createAsyncThunk(
    "deletedriver/deleteDriverAction",
    async (did, { rejectWithValue }) => {
        try {
            const { data } = await request.delete(`/drivers/${did}`, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getDriverEarnings = createAsyncThunk(
    "driverEarnings/getDriverEarnings",
    async (did, { rejectWithValue }) => {

        let url = `/orders/graph/${did}?type=driver&duration=30&status=completed`;

        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getDriverPendingEarnings = createAsyncThunk(
    "driverPendingEarnings/getDriverPendingEarnings",
    async (did, { rejectWithValue }) => {

        let url = `/orders/graph/${did}?type=driver&duration=30&status=pending,requested,picked`;

        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export { getAllDrivers, getDriverDetails, getDriverOrders, deleteDriverAction, getDriverEarnings, getDriverPendingEarnings }