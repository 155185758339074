import axios from 'axios'

const request = axios.create({
    baseURL: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:5001/api/v1' : 'https://api.bellhopt.com/api/v1',
    withCredentials: true,
})

request.interceptors.response.use(
    response => response,
    error => {
        if (!error.response) {
            const error = {
                error: 'No response from the server, please try again later',
            };
            const data = { response: { data: error } };
            return Promise.reject(data);
        }
        return Promise.reject(error);
    },
);

export default request