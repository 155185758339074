import request from '../utils/request'

export const DriverPromotionService = {

    getDriverPromotions: async () => {
        try {
            const response = await request.get('/admin/promotions')
            return response.data;
        } catch (error) {
            console.log(error)
        }
    },
    
    saveDriverPromotion: async (formData) => {
        try {
            const reponse  = await request.post('admin/promotion', formData)
        } catch (error) {
            console.log(error)
        }
    },

    getDriverPromotion: async (pid) => {
        try {
            const response  = await request.get('admin/promotion/'+pid)
            return response.data.promotion
        } catch (error) {
            console.log(error)
        }
    },
    updateDriverPromotion: async (pid, formData) => {
        try {
            const reponse  = await request.post('admin/promotion', formData)
        } catch (error) {
            console.log(error)
        }
    },
    
}