import React, { useEffect, useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import ActivePending from '../components/ActivePending'
import DriversListComponent from '../components/driverComponents/DriversListComponent'
import { getAllDrivers } from '../store/thunks/driverActions';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import { deleteDriverReset, driverListReset } from '../store/slices/driverSlices';

function DriverListScreen() {

  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);

  const [active,] = useState('Active Driver List');
  const [pending,] = useState('Pending Requests');
  const [activeTab, setActiveTab] = useState(active);

  const { loading, driverList, error } = useSelector((state) => state.allDrivers)
  const { message: messageDelete, loading: loadingDelete, successDelete, error: errorDelete } = useSelector((state) => state.driverDelete)

  let driverData = driverList && driverList.filter((driver) => {
    if (activeTab == active) {
      if (driver.isVerified && driver.checkrVerified === "clear" && driver.isOnBoarded) return driver
    }
    if (activeTab == pending) {
      if (!driver.isVerified || driver.checkrVerified !== "clear" || !driver.isOnBoarded) return driver
    }
  })

  driverData = driverData && driverData.map((driver, index) => {
    return {
      id: driver._id,
      name: `${driver.firstName} ${driver.lastName}`,
      email: driver.email,
      mobile: driver.phoneNumber,
      checkrVerified: driver.checkrVerified,
      stripeVerified: driver.isOnBoarded ? true : false,
      emailVerified: driver.isVerified === true ? true : false
    }
  })

  useEffect(() => {
    let status = activeTab == active ? 'active' : 'pending';
    dispatch(getAllDrivers({ limit, status }))
  }, [limit, activeTab])

  useEffect(() => {
    setTimeout(() => {
      successDelete && dispatch(deleteDriverReset())
      errorDelete && dispatch(deleteDriverReset())
      error && dispatch(driverListReset())
    }, 2000)
  }, [successDelete, error, errorDelete])

  return (
    <div className="flex w-full h-screen overflow-auto">

      {/* Error show Here */}
      {messageDelete && (
        <Alert
          severity="success"
          sx={{
            position: "fixed",
            width: "90%",
            margin: "auto",
            top: 20,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <strong>{messageDelete}</strong>
        </Alert>
      )}

      {error && (
        <Alert
          severity="error"
          sx={{
            position: "fixed",
            width: "90%",
            margin: "auto",
            top: 20,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <strong>{error}</strong>
        </Alert>
      )}

      {errorDelete && (
        <Alert
          severity="error"
          sx={{
            position: "fixed",
            width: "90%",
            margin: "auto",
            top: 20,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <strong>{errorDelete}</strong>
        </Alert>
      )}

      <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
        <div className="px-10 bg-white">
          <AdminHeader heading={"Drivers List"} pages={"Pages / Drivers List"} />
        </div>

        <div className="px-6 py-5 h-screen overflow-auto">

          <ActivePending active={active} pending={pending} activeTab={activeTab} setActiveTab={setActiveTab} />

          <DriversListComponent data={driverData} setLimit={setLimit} activeTab={activeTab} />
        </div>
      </div>
    </div>
  )
}

export default DriverListScreen