import { createSlice } from "@reduxjs/toolkit";
import { deleteProperty, getPropertyDetails, updateProperty } from "../thunks/propertyActions";

const propertyUpdateSlice = createSlice({
    name: 'propertyUpdate',
    initialState: {
        loading: false,
        success: false,
        message: '',
        error: ''
    },
    reducers: {

        updatePropertyReset: (state) => {
                state.loading = false;
                state.success = false;
                state.message = '';
                state.error = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateProperty.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProperty.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload.success;
                state.message = action.payload.msg;
            })
            .addCase(updateProperty.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            })
    }
})

const propertyDetailsSlice = createSlice({
    name: 'propertyDetails',
    initialState: {
        loading: false,
        property: {},
        error: ''
    },
    reducers: {
        hostPropertyDetailsReset: (state) => {
            state.loading = false;
            state.property = {};
            state.error = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPropertyDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPropertyDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.property = action.payload.property;
            })
            .addCase(getPropertyDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            })
    }
})

const propertyDeleteSlice = createSlice({
    name: 'propertyDelete',
    initialState: {
        loading: false,
        success: false,
        message: '',
        error: ''
    },
    reducers: {
        deletePropertyReset: (state) => {
            state.loading = false;
            state.success = false;
            state.message = '';
            state.error = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteProperty.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteProperty.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload.success;
                state.message = action.payload.message;
            })
            .addCase(deleteProperty.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            })
    }
})

export const { hostPropertyDetailsReset } = propertyDetailsSlice.actions
export const { deletePropertyReset } = propertyDeleteSlice.actions
export const { updatePropertyReset } = propertyUpdateSlice.actions

export { propertyDeleteSlice, propertyDetailsSlice, propertyUpdateSlice }