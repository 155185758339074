import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ToggleButton from "../utilityComponents/ToggleButton";
import request from "../../utils/request";
import { useDispatch, useSelector } from "react-redux";
import { getHostDetails } from "../../store/thunks/HostActions";

const AllParserTable = ({
  data,
  getAllParsers,
  setSuccessMessage,
  setErrorMessage,
}) => {
  const dispatch = useDispatch();
  const [updated, setUpdated] = useState(false);
  const { host } = useSelector((state) => state.hostDetails);

  // const handleDelete = async (id) => {
  //   if (!window.confirm("Are you sure you want to delete this parser?")) {
  //     return;
  //   }

  //   try {
  //     const { data } = await request.delete(`/parsers/parser/${id}`);
  //     if (data.success) {
  //       setSuccessMessage(data.msg);
  //       getAllParsers();
  //     }
  //   } catch (error) {
  //     console.log(error.response.data.error);
  //     setErrorMessage(error.response.data.error);
  //   }
  // };

  const handleToggleFromHost = async (parserId) => {
    try {
      const { data } = await request.put(`/parsers/parser/${host._id}`, {
        parserId: parserId,
      });
      if (data.success) {
        setSuccessMessage(data.msg);
        setUpdated(true);
      }
    } catch (error) {
      setErrorMessage(error.response.data.error);
    }
  };

  useEffect(() => {
    if (updated) {
      dispatch(getHostDetails(host._id));
      setUpdated(false);
    }
  }, [updated]);
  
  return (
    <div className="relative overflow-x-auto border-[0.5px] border-solid border-[#CCCCCC] rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-[black] text-[white]">
          <tr>
            <th className="px-4 py-3">Parser Name</th>
            <th className="px-4 py-3">Email Subject</th>
            <th className="px-4 py-4">Included</th>
            <th className="px-4 py-4">Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((entry, index) => (
              <tr
                key={entry._id}
                className="bg-white border-b-[#CCCCCC] border-b-[0.5px]"
              >
                <td className="px-4 w-fit max-w-[200px]">
                  <p className="truncate">{entry.parserName}</p>
                </td>

                <td className="px-4 w-fit max-w-[200px]">
                  <p className="truncate">{entry.emailSubject}</p>
                </td>

                {/* Activate */}
                <td className="px-4 w-fit min-w-[190px]">
                  <ToggleButton
                    isChecked={host?.parserIds?.includes(entry._id)}
                    update={() => handleToggleFromHost(entry._id)}
                  />
                </td>

                <td className="py-4 px-4 flex justify-between w-fit gap-2">
                  <NavLink
                    to={`/hostList/${entry.hostID}/parser/${entry._id}?new=false`}
                    className="bg-viewBlue p-1 px-4 rounded-[21px] text-[white]"
                  >
                    View
                  </NavLink>
                  {/* <NavLink
                  to={`/hostList/${entry.hostID}/parser/${entry._id}?edit=true`}
                  className="bg-editGreen p-1 px-4 rounded-[21px] text-[white]"
                >
                  Edit
                </NavLink> */}
                  {/* <NavLink
                    onClick={() => handleDelete(entry._id)}
                    className="bg-deleteRed p-1 px-4 rounded-[21px] text-[white]"
                  >
                    Delete
                  </NavLink> */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllParserTable;
