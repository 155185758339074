import React, { useEffect, useState } from "react";
import request from "../../utils/request";
import AllParserTable from "./AllParserTable";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHostDetails } from "../../store/thunks/HostActions";
import ToggleButton from "../utilityComponents/ToggleButton";

const AllParsersComponent = ({ setErrorMessage, setSuccessMessage }) => {
  // useStates
  const { host } = useSelector((state) => state.hostDetails);
  const [loading, setLoading] = useState(false);
  const [parsers, setParsers] = useState([]);
  const dispatch = useDispatch();
  const [updated, setUpdated] = useState(false);

  const getAllParsers = async () => {
    console.log("hello");
    setLoading(true);
    try {
      const { data } = await request.get(`/parsers`);
      if (data.success) {
        setParsers(data.parsers);
      }
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.log(error.response.data.error);
    }
    setLoading(false);
  };

  const handleAllowed = async () => {
    try {
      const { data } = await request.patch(`/hosts/automation/${host._id}`, {
        automationAllowed: host?.automationAllowed ? false : true,
      });
      if (data.success) {
        setSuccessMessage(data.msg);
        setUpdated(true);
      }
    } catch (error) {
      setErrorMessage(error.response.data.error);
    }
  };

  const handleActivate = async () => {
    try {
      const { data } = await request.patch(`/hosts/automation/${host._id}`, {
        automationActiveAdmin: host?.automationActiveAdmin ? false : true,
      });
      if (data.success) {
        setSuccessMessage(data.msg);
        setUpdated(true);
      }
    } catch (error) {
      setErrorMessage(error.response.data.error);
    }
  };

  const sendTestEmail = async () => {
    try {
      const { data } = await request.post(`/hosts/oauth/test/${host._id}`);
      if (data.success) {
        setSuccessMessage(data.msg);
      }
    } catch (error) {
      setErrorMessage(error.response.data.error);
      console.log(error.response.data.error);
    }
  };

  useEffect(() => {
    if (updated) {
      dispatch(getHostDetails(host._id));
      setUpdated(false);
    }
  }, [updated]);

  useEffect(() => {
    getAllParsers();
  }, []);

  return (
    <div>
      {/* Table will go here */}
      <div className="text-[24px] font-medium font-segoe flex gap-3 items-center justify-between mb-2">
        <p>Parsers</p>

        <div className="flex gap-5">
          {/* Allowed */}
          <div className="w-fit flex items-center text-base1 gap-2">
            <label>Allowed</label>
            <ToggleButton
              isChecked={host?.automationAllowed}
              update={() => handleAllowed()}
            />
          </div>
          <div className="w-fit flex items-center text-base1 gap-2">
          <label>Activate</label>
            <ToggleButton
              isChecked={host?.automationActiveAdmin}
              update={() => handleActivate()}
            />
          </div>
          <button
            onClick={() => sendTestEmail()}
            className="bg-black text-white py-1 px-6 rounded-3xl text-base2"
          >
            Send Test Email
          </button>
          <NavLink
            to={`parser/1?new=true`}
            className="bg-black text-white px-6 py-1 rounded-3xl text-base2"
          >
            Add
          </NavLink>
        </div>
      </div>

      {parsers && (
        <AllParserTable
          data={parsers}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          getAllParsers={getAllParsers}
        />
      )}
    </div>
  );
};

export default AllParsersComponent;
