import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

export default function DialogModal ({ openModal, setOpenModal, children }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={openModal}>
                <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-40">
                    <div className="bg-white rounded-lg shadow-lg max-w-lg w-full mx-4">
                        {children}
                    </div>
                </div>
            </Fade>
        </Modal >
    )
}

DialogModal.Content = function DialogModalContent({
    title,
    children,
}) {
    return (
        <>
            <div className="px-6 pt-4">
                <div class="font-medium text-gray-900">{title}</div>
            </div>
            <hr className="my-3 m-4 gray-600 opacity-25" />
            <div className='px-6 pt-2 pb-2 text-sm text-gray-600'>
                {children}
            </div>
        </>
    );
}

DialogModal.Footer = function DialogModalFooter({
    children,
}) {
    return (
        <div className="flex justify-end p-4">
            {children}
        </div>
    );
};
