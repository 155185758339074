import React, { useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import { CircularProgress } from '@mui/material';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useNavigate } from 'react-router-dom';
import OffersForm from '../components/OffersForm';
import { OfferService } from '../services/OfferService';

const OfferCreate = () => {
    const navigate = useNavigate();
    const [_error, _setError] = useState();
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        bannerImage: '',
        afterProduct: 0,
        expireAt: '',
        showForm: '',
        logo: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!formData.title || !formData.afterProduct || !formData.description || !formData.expireAt || !formData.bannerImage) {
            setError('All fields are required');
            setLoading(false);
            return;
        }

        let data = formData;
        data.showForm = data.showForm ? true : false;
        await OfferService.createOffer(data);

        setMessage('The offer has been saved');
        setLoading(false);
        // Reset form data after successful submission if needed
        setFormData({
            description: '',
            bannerImage: null,
            position: '',
            afterProduct: '',
            expireAt: ''
        });
        setTimeout(() => {
            navigate('/offers');
        }, 1500)
    };

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-10 bg-white">
                    <AdminHeader
                        heading={"Create New Offer"}
                        pages={"Pages / Offers / Create New Offer"}
                    />
                </div>
                <div className="px-6 py-5 h-full overflow-auto">
                    <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 min-h-[80%]" style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {
                            loading ? <CircularProgress /> : (
                                <OffersForm
                                    message={message}
                                    handleSubmit={handleSubmit}
                                    formData={formData}
                                    setFormData={setFormData}
                                    loading={loading}
                                    error={error}
                                />)
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default OfferCreate