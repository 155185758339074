import React, { useEffect } from 'react'
import PropertyDetailsComponent from "../components/propertyComponents/PropertyDetailsComponent";
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import AdminHeader from "../components/AdminHeader";


function PropertyDetailsScreen() {

  const navigate = useNavigate()

  const { adminInfo } = useSelector((state) => state.login);

  useEffect(() => {

    if (!adminInfo) {
      navigate('/login')
    }
  }, [adminInfo, navigate]);

  return (

    <>
      {/* //overflow auto to attain sticky position */}
      <div className="flex w-full h-screen overflow-auto">
        <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
          <div className="px-10 bg-white">
            <AdminHeader heading={"Drivers List"} pages={"Pages / Drivers List"} />
          </div>

          {/* RIGHT  */}
          <div className="flex flex-col w-full mobile:w-full px-10 tablet:px-0 mx-auto">
            <PropertyDetailsComponent />
          </div>
        </div>
      </div>
    </>
  )
}

export default PropertyDetailsScreen