import request from "../../utils/request";
import { createAsyncThunk } from "@reduxjs/toolkit";

const updateProperty = createAsyncThunk(
    "updateProperty/updateProperty",
    async (credentials, { rejectWithValue }) => {
  
      try {
        const { data } = await request.patch(
          `/properties/${credentials.pid}`,
          credentials.propertyCredentials,
          { withCredentials: true }
        );
  
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );
  
  const getPropertyDetails = createAsyncThunk(
    "propertyDetails/getPropertyDetails",
    async (id, { rejectWithValue }) => {
      try {
        const { data } = await request.get(`/properties/${id}`, {
          withCredentials: true,
        });
  
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );
  
  const deleteProperty = createAsyncThunk(
    "deleteProperty/deleteProperty",
    async (id, { rejectWithValue }) => {
      try {
        const { data } = await request.delete(`/properties/${id}`, {
          withCredentials: true,
        });
  
        return data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  export {deleteProperty, updateProperty, getPropertyDetails}