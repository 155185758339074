import React, { useEffect, useState } from 'react'
import redCheckIcon from '../../icons/redCheck.svg'
import greyCheckIcon from '../../icons/greyCheck.svg'

function OwnPackageProduct({ product, selectedProducts, setSelectedProducts, isEditMode }) {
    const { _id } = product
    const { productId } = product
    
    const [isSelected, setIsSelected] = useState();

    let name, price, image, soldBy = null
    if(product.items && isSelected){
        name = product.description
        price = product.items[0].price.regular
        image = product.images[0].sizes[1].url
        soldBy = product.items[0].soldBy
    }

    const handleProductAdd = () => {
        if (isSelected) {
            setIsSelected(false);

            // if else is put here to handle edit and create functionality in one place
            if (_id)
                setSelectedProducts(selectedProducts.filter((item) => item.productId !== _id));
            else
                setSelectedProducts(selectedProducts.filter((item) => item.productId !== productId));

        } else {
            setIsSelected(true);
            setSelectedProducts([...selectedProducts, { ...product, productId: _id }]);
        }
    }

    useEffect(() => {
        // if else is put here to handle edit and create functionality in one place
        if (_id) {
            setIsSelected(selectedProducts?.some((item) => item.productId === _id))
        }
        else {
            setIsSelected(selectedProducts?.some((item) => item.productId === productId))
        }
    }, [selectedProducts])



    return (
        <div
            className={`border-[1px] ${isSelected && !isEditMode ? "border-[red]" : "border-[#D9D9D9]"
                } relative cursor-pointer`}
            // } relative cursor-pointer ${isPackageComplete && !isSelected && 'cursor-not-allowed'}`}
            onClick={() => handleProductAdd()}
        >
            <div className="p-2 flex flex-col gap-2 justify-between h-full" >

                <img className="w-[120px] h-[120px] mx-auto" src={image || product.images[0]} />


                <h1 className="font-segoe font-bold text-base3">{product.name || name}</h1>
                <p className='base-2'>{product.price || price}</p>
                <p className='mt-auto text-light'>Sold per: {product.soldBy || soldBy}</p>

                {/* Red Check Icon */}
                <div className="absolute right-1 top-1">
                    {isEditMode ? <>

                    </>
                        : <>
                            {
                                isSelected ? (
                                    <img src={redCheckIcon} alt="red check" />
                                ) : (
                                    <img src={greyCheckIcon} alt="check" />
                                )}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default OwnPackageProduct