import React from "react";
import { useState, useEffect } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    BarChart,
    Bar,
    Legend,
    PieChart,
    Pie,
    Sector,
    Cell,
    Label,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders, getAllOrdersCount } from "../store/thunks/adminActions";
import { CircularProgress } from "@mui/material";
import Selectbox_months from "./utilityComponents/Selectbox_months";


//                 red,     green,      blue,       yellow
const COLORS = ["#EF4949", "#2BC155", "#009DDD", "#FFFF00"];

const TotalOrders = () => {
    const dispatch = useDispatch();

    const [month, setMonth] = useState(new Date().getMonth() + 1);

    // -------------------- Use Selectors ----------------------
    const { loading, orderCompletedCount, orderPendingCount, orderRequestedCount, orderPickedCount } = useSelector(
        (state) => state.allOrders.orderCount
    );

    const [orderAllCount, setorderAllCount] = useState(0);

    // Requested: red
    // completed: Green
    // Pending: Blue
    // picked: green
    const data = [{ name: "requested", orders: orderRequestedCount }, { name: "completed", orders: orderCompletedCount }, { name: "pending", orders: orderPendingCount }, { name: "picked", orders: orderPickedCount }]

    useEffect(() => {

        dispatch(getAllOrdersCount(month));
    }, [month]);

    useEffect(() => {
        setorderAllCount(orderCompletedCount + orderPendingCount + orderRequestedCount + orderPickedCount);
    }, [orderCompletedCount]);
    return (
        <div className="w-[28%] mr-auto min-w-[225px] max-w-[500px] p-3 shadow-4xl bg-white my-3 rounded-xl gap-x-1">
            <div className="w-full flex flex-col justify-around h-full m-auto">
                <div className="flex justify-between">
                    <h1 className="font-segoe font-bold text-[20px]">Orders</h1>

                    <Selectbox_months month={month} setMonth={setMonth} />
                </div>

                <div className="flex items-center w-full justify-start">

                    {!loading ? (

                        <ResponsiveContainer width="60%" height={120}>
                            <PieChart width={120} height={140}>
                                <Pie
                                    data={data}
                                    innerRadius={20}
                                    outerRadius={50}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="orders"
                                >
                                    {data.map((entry, index) => (
                                        <Cell
                                            key={index}
                                            fill={COLORS[index]}
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    ) : (
                        <div className='flex justify-center h-10 w-full'>
                            <CircularProgress
                                color="inherit"
                                className="mr-2"
                                size={"30px"}
                            />
                        </div>
                    )
                    }

                    <div className="min-w-[60px]">
                        <p>Total orders</p>
                        <h1 className="font-segoe text-[40px] font-bold pl-2">
                            {orderAllCount? orderAllCount: '...'}
                        </h1>
                    </div>
                </div>

                <div className="pl-">
                    <div className="flex items-center gap-2">
                        <label className="bg-green w-2 h-2 inline-block"></label>
                        <p> Completed Orders ({orderCompletedCount? orderCompletedCount: 0})</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <label className="bg-yellow w-2 h-2 inline-block"></label>
                        <p> Pending Orders ({orderPendingCount? orderPendingCount: 0})</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <label className="bg-red w-2 h-2 inline-block"></label>
                        <p> Requested Orders ({orderRequestedCount? orderRequestedCount: 0})</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <label className="bg-blue w-2 h-2 inline-block"></label>
                        <p> Picked Orders ({orderPickedCount? orderPickedCount: 0})</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TotalOrders;
