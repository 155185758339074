import React, { useEffect, useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import PackageCard from '../components/PackageCard'
import { useDispatch, useSelector } from 'react-redux'
import { getPackages } from '../store/thunks/packageActions'
import { Alert, CircularProgress } from '@mui/material'
import PackageCreateComponent from '../components/packageComponents/PackageCreateComponent'
import PackageEditComponent from '../components/packageComponents/PackageEditComponent'

function AdminPackages() {

  const dispatch = useDispatch()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editPkgId, setEditPkgId] = useState();
  const [message, setMessage] = useState();
  const [_error, _setError] = useState();
  // const [errorEdit, setErrorEdit] = useState();

  // using message state for this purpose filhaal
  const [successCreate, setSuccessCreate] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  // const [packageList, setPackageList] = useState([]);


  const { loading, packageList, error } = useSelector((state) => state.packages)


  console.log("package list ", packageList)



  function packageById(id)
  {
    const filterPackage = packageList.find((p) => p.packageId === id)
    return filterPackage
  }


  useEffect(() => {

    if (successCreate || successEdit)
      dispatch(getPackages(1));
  }, [successCreate, successEdit])  

  useEffect(()=>{
    dispatch(getPackages(1))
  }, [])

  useEffect(() => {
    setTimeout(() => {

      message && setMessage()
      _error && _setError()
      successCreate && setSuccessCreate(false)
      successEdit && setSuccessEdit(false)
      successDelete && setSuccessDelete(false)
    }, 3000)
  }, [message, _error, successDelete, successCreate, successEdit])

  return (
    <div className="flex w-full h-screen overflow-auto">
      <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
        <div className="px-10 bg-white">
          <AdminHeader
            heading={"Packages List"}
            pages={"Pages / Packages"}
          />
        </div>

        {message &&
          <Alert
            severity="success"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            {message}
          </Alert>
        }
        {error && <Alert severity='error'>
          {error}
        </Alert>
        }
        {_error &&
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            {_error}
          </Alert>
        }


        <div className="px-6 py-5 h-full overflow-auto">
          <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 min-h-[80%]">
            <div className="w-[95%] m-auto flex flex-col">
              <div className="flex justify-between items-center p-5 border-b-lightborder border-b-[0.5px] w-full">
                <h1 className="text-base3 font-segoe font-semibold flex items-center gap-x-2">
                  <label>Packages List</label>
                </h1>
                <button className='bg-[black] rounded-[50px]' onClick={() => setIsCreateModalOpen(!isCreateModalOpen)}><p className='text-[white] py-3 px-7'>Create New Package</p></button>

              </div>

              <div className='cards grid grid-cols-4 desktop:grid-cols-5 gap-3 my-5'>

                {
                  loading ? (

                    Array.apply(null, Array(4)).map((x, index) => (
                      <div key={index} className="bg-lightgrey flex flex-col gap-4 justify-center items-start w-full h-[300px] rounded-[10px] p-3">
                        <div className='bg-white h-[100px] w-full rounded'></div>
                        {
                          Array.apply(null, Array(5)).map((x, index) => (
                            <div key={index} className='bg-white h-2 w-full rounded'></div>
                          ))
                        }
                        <div className='w-20 h-8 rounded-[50px] bg-white mt-auto'></div>
                      </div>
                    ))
                  ) : (

                    packageList?.length > 0 && packageList.map((pkg, index) => (
                      <PackageCard key={index} pkg={pkg} setIsEditModalOpen={setIsEditModalOpen} setEditPkgId={setEditPkgId} setSuccessDelete={setSuccessDelete} setMessage={setMessage} _setError={_setError} />
                    ))
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <PackageCreateComponent isModalOpen={isCreateModalOpen} setIsModalOpen={setIsCreateModalOpen} setMessage={setMessage} setErrorCreate={_setError} setSuccessCreate={setSuccessCreate} />

      <PackageEditComponent isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} setMessage={setMessage} setErrorEdit={_setError} setSuccessEdit={setSuccessEdit} editPkgId={editPkgId} />
    </div>
  )
}

export default AdminPackages