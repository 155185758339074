import React from 'react'
import LoadingTableComponent from './utilityComponents/LoadingTableComponent'
import { NavLink } from 'react-router-dom'
import DeletePropertyOffer from './DeletePropertyOffer'

const PropertyOffersTable = ({ data, limit, loading, deleteRow }) => {

    const isURL = (str) => {
        const urlPattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        );
        return urlPattern.test(str);
    }

    return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-[black] text-[white]">
                <tr>
                    <th className="px-4 py-3">
                        Offer
                    </th>
                    <th className="px-4 py-3">
                        Useage
                    </th>
                    <th className="px-4 py-3">
                        Flow/Link
                    </th>
                    <th className="px-4 py-3">
                        Actions
                    </th>
                </tr>
            </thead>
            {loading ? (
                <LoadingTableComponent cols={4} />
            ) : (
                data?.length > 0 && data.map((entry, index) => (
                    <tbody>
                        <tr
                            key={entry._id}
                            className="bg-white border-b-[#CCCCCC] border-b-[0.5px] py-4"
                        >
                            <td className="px-4 w-fit min-w-[50px]">
                                {entry.offerId?.title}
                            </td>
                            <td className="px-4 w-fit min-w-[50px]">
                                {entry.usage}
                            </td>
                            <td className="px-4 w-fit min-w-[50px]">
                                {isURL(entry.link) ? (<a href={entry.link} target='_blank' className='p-1 px-4 rounded-[21px] flex gap-2 
                                border border w-[60px]'> Click</a>) : (entry.link)}
                            </td>
                            <td className="py-4 px-4 w-fit flex m-2">
                                <NavLink to={`/property-offers/edit/${entry._id}`} className="flex align-items justify-center 
                                    p-1 px-4 rounded-[6px] flex gap-2 border w-[70px] ml-2 font-small">
                                    <p>Edit</p>
                                </NavLink>
                                <DeletePropertyOffer offerId={entry._id} deleteRow={deleteRow} />
                            </td>
                        </tr>
                    </tbody>
                )
                )
            )}
        </table>
    )
}

export default PropertyOffersTable