import { createSlice } from "@reduxjs/toolkit";
import { getProducts } from "../thunks/krogerActions";

const productsSlice = createSlice({
    name: "products",
    initialState: {
        loading: false,
        products: null,
        error: "",
        term: "Grocery",
    },
    reducers: {
        updateTerm: (state, action) => {
            state.term = action?.payload || "Grocery";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.loading = false;
                // state.error = action.payload.error
            });
    },
});

export const { updateTerm } = productsSlice.actions;

export {productsSlice}