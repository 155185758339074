import request from "../../utils/request";
import { createAsyncThunk } from "@reduxjs/toolkit";

const login = createAsyncThunk(
    "login/login",
    async (loginCredentials, { rejectWithValue }) => {

        try {
            const { data } = await request.post("/hosts/login", loginCredentials, {
                withCredentials: true,
            });

            localStorage.setItem("adminInfo", JSON.stringify(data.user));
            
            // if(data)
            if(data.user.isAdmin){
                return data;
            }
            else{
                return rejectWithValue({error: "You are not an Admin"});
            }

        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const signup = createAsyncThunk(
    "signup/signup",
    async (signUpCredentials, { rejectWithValue }) => {
        try {
            const { data } = await request.post(
                "/hosts/register",
                signUpCredentials,
                { withCredentials: true }
            );

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getRecentOrders = createAsyncThunk(
    "recentOrders/getRecentOrders",
    async (limit, { rejectWithValue }) => {

        try {
            const { data } = await request.get(`/orders?status=completed&limit=${limit}`, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

// const getAllOrders = createAsyncThunk(
//     "allOrders/getAllOrders",
//     async (month, { rejectWithValue }) => {

//         try {
//             const { data } = await request.get(`/orders/count?month=${month}`, {
//                 withCredentials: true,
//             });

//             return data;
//         } catch (err) {
//             return rejectWithValue(err.response.data);
//         }
//     }
// );

const getAllOrdersCount = createAsyncThunk(
    "allOrders/getAllOrders",
    async (month = null, { rejectWithValue }) => {

        let url
        if(month){
            url = `/orders/count?month=${month}`
        }
        else{
            url = `/orders/count`
        }
        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });
            
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getAllOrders_completed = createAsyncThunk(
    "allOrdersCompleted/getAllOrders_completed",
    async (params, { rejectWithValue }) => {

        const { limit, month } = params

        let url = "/orders?status=completed"
        if (month) {
            url = `/orders?status=completed&month=${month}`
        }
        if (limit) {
            url = `/orders?status=completed&limit=${limit}`
        }
        if (limit && month) {
            url = `/orders?status=completed&limit=${limit}&month=${month}`
        }

        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });
            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getAllOrders_pending = createAsyncThunk(
    "allOrdersPending/getAllOrders_pending",
    async (params, { rejectWithValue }) => {

        const { limit, month } = params

        let url = "/orders?status=pending"
        if (month) {
            url = `/orders?status=pending&month=${month}`
        }
        if (limit) {
            url = `/orders?status=pending&limit=${limit}`
        }
        if (limit && month) {
            url = `/orders?status=pending&limit=${limit}&month=${month}`
        }

        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getAllOrders_picked = createAsyncThunk(
    "allOrdersPicked/getAllOrders_picked",
    async (params, { rejectWithValue }) => {

        const { limit, month } = params

        let url = "/orders?status=picked"
        if (month) {
            url = `/orders?status=picked&month=${month}`
        }
        if (limit) {
            url = `/orders?status=picked&limit=${limit}`
        }
        if (limit && month) {
            url = `/orders?status=picked&limit=${limit}&month=${month}`
        }

        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getAllOrders_requested = createAsyncThunk(
    "allOrdersrequested/getAllOrders_requested",
    async (params, { rejectWithValue }) => {

        const { limit, month } = params

        let url = "/orders?status=requested"
        if (month) {
            url = `/orders?status=requested&month=${month}`
        }
        if (limit) {
            url = `/orders?status=requested&limit=${limit}`
        }
        if (limit && month) {
            url = `/orders?status=requested&limit=${limit}&month=${month}`
        }

        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getAllOrders_paymentCompleted = createAsyncThunk(
    "allOrderspaymentCompleted/getAllOrders_paymentCompleted",
    async (params, { rejectWithValue }) => {

        const { limit, month } = params

        let url = "/orders?status=paymentCompleted"
        if (month) {
            url = `/orders?status=paymentCompleted&month=${month}`
        }
        if (limit) {
            url = `/orders?status=paymentCompleted&limit=${limit}`
        }
        if (limit && month) {
            url = `/orders?status=paymentCompleted&limit=${limit}&month=${month}`
        }

        try {
            const { data } = await request.get(url, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const getOrderDetails = createAsyncThunk(
    "orderDetails/getOrderDetails",
    async (oid, { rejectWithValue }) => {

        try {
            const { data } = await request.get(`/orders/${oid}`, {
                withCredentials: true,
            });

            return data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
)

export {
    login,
    signup,
    getAllOrdersCount,
    getRecentOrders,
    getAllOrders_completed,
    getAllOrders_pending,
    getAllOrders_picked,
    getAllOrders_requested,
    getAllOrders_paymentCompleted,
    getOrderDetails
}