import React, { useEffect, useRef, useState } from 'react';
import AdminHeader from '../components/AdminHeader';
import { Alert } from '@mui/material';
import { db } from "../firebase";
import { addDoc, collection, serverTimestamp, onSnapshot, query, where, orderBy, doc, updateDoc, getDoc, getDocs } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { TimeAndDateFormat } from '../utils/TimeAndDateFormat';
import request from '../utils/request';

const SupportChat = () => {
    const [_error, _setError] = useState('');
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const { adminInfo } = useSelector((state) => state.login);
    const scroll = useRef();
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        { title: 'Tab 1', content: 'Order Related Chat' },
        { title: 'Tab 2', content: 'General Chat' },
    ];

    useEffect(() => {
        fetchConversations();
    }, [activeTab]);

    useEffect(() => {
        if (scroll?.current) {
            scroll.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        if (selectedConversation) {
            fetchMessages(selectedConversation.id);
        }
    }, [selectedConversation]);

    const fetchConversations = async () => {
        let q;
        if (activeTab === 0) {
            q = query(
                collection(db, 'conversations'),
                where('participants', 'array-contains', adminInfo._id),
                where('orderID', '!=', null)
            );
        } else {
            q = query(
                collection(db, 'conversations'),
                where('participants', 'array-contains', adminInfo._id),
                where('orderID', '==', null)
            );
        }

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const updatedConversations = [];

            for (const docSnapshot of querySnapshot.docs) {
                const conversationData = docSnapshot.data();

                const senderDocRef = doc(db, 'users', conversationData.senderID);

                const unreadMessagesQuery = query(
                    collection(db, 'messages'),
                    where('conversationId', '==', docSnapshot.id),
                    where('user._id', '==', conversationData.senderID),
                    where('isRead', '==', false)
                );

                const [senderDocSnap, unreadMessagesSnap] = await Promise.all([
                    getDoc(senderDocRef),
                    getDocs(unreadMessagesQuery)
                ]);

                if (senderDocSnap.exists()) {
                    const senderData = senderDocSnap.data();

                    updatedConversations.push({
                        id: docSnapshot.id,
                        senderName: senderData.firstName,
                        senderProfileImage: senderData.profilePicture,
                        unreadCount: unreadMessagesSnap.docs.length,
                        ...conversationData,
                    });
                }
            }

            setConversations(updatedConversations);
            
        });
        setMessages([])
        setSelectedConversation(null)

        return unsubscribe;
    };

    const fetchMessages = async (conversationId) => {
        const q = query(
            collection(db, 'messages'),
            where('conversationId', '==', conversationId),
            orderBy('createdAt', 'asc')
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const updatedMessages = [];
            querySnapshot.forEach((doc) => {
                updatedMessages.push({ id: doc.id, ...doc.data() });
            });
            setMessages(updatedMessages);
        });

        if (selectedConversation) {
            const messagesRef = collection(db, "messages");
            const messageQuery = query(messagesRef,
                where("conversationId", "==", conversationId),
                where("isRead", "==", false),
                where("user._id", "==", selectedConversation.participants[1])
            );

            const querySnapshot = await getDocs(messageQuery);
            querySnapshot.forEach(async (document) => {
                const docRef = doc(db, "messages", document.id);
                await updateDoc(docRef, { isRead: true });
            });
        }

        return unsubscribe;
    };

    const sendMessage = async (message) => {
        if (!selectedConversation) {
            alert('Please select a conversation first.');
            return;
        }
    
        if (!adminInfo || !adminInfo._id) {
            console.error('Admin info is not defined');
            return;
        }
    
        if (!selectedConversation.senderID) {
            console.error('Selected conversation senderID is not defined');
            return;
        }
    
        const user = {
            _id: adminInfo._id,
            name: 'Admin',
            avatar: adminInfo.profilePicture || ''
        }
    
        const createdAt = serverTimestamp();
        const selectedConversationId = selectedConversation.id;
    
        try {
            await request.post('/admin/send-push-notification', {
                driverId: selectedConversation.senderID,
                body: message,
                title: ''
            });
    
            await addDoc(collection(db, 'messages'), {
                conversationId: selectedConversation.id,
                text: message,
                isRead: false,
                user: {
                    _id: adminInfo._id,
                    name: 'Admin',
                    avatar: adminInfo.profilePicture
                },
                createdAt: serverTimestamp(),
            });
    
            await updateDoc(doc(db, "conversations", selectedConversation.id), {
                lastMessage: message,
                createdAt: createdAt,
            });
    
            console.log("Conversation updated with last message.");
        } catch (error) {
            console.error("Error updating conversation: ", error);
        }
    };    

    const handleConversationClick = (conversation) => {
        setSelectedConversation(conversation);
    };

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-10 bg-white">
                    <AdminHeader
                        heading={"Support Chat"}
                        pages={"Pages / Support Chat"}
                    />
                </div>
                {_error &&
                    <Alert
                        severity="error"
                        sx={{
                            position: "fixed",
                            width: "90%",
                            margin: "auto",
                            top: 20,
                            left: 0,
                            right: 0,
                            zIndex: 10,
                        }}
                    >
                        {_error}
                    </Alert>
                }
                <div className="px-6 py-5 h-full overflow-auto">
                    <div className="m-auto shadow-4xl bg-white rounded-xl w-full tablet:w-[90%] gap-x-5 min-h-[80%]">
                        <div className="w-[95%] m-auto flex flex-col">
                            <div className="flex border-b w-full" >
                                {tabs.map((tab, index) => (
                                    <button
                                        key={index}
                                        className={`p-8 w-1/2 ${activeTab === index ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                                        onClick={() => setActiveTab(index)}
                                    >
                                        {tab.content}
                                    </button>
                                ))}
                            </div>
                            {conversations.length > 0 ? (
                                <div className='cards gap-3 my-5'>
                                    <div className="flex h-screen overflow-hidden" style={{ height: 'calc(100vh - 35vh)' }}>
                                        <div className="w-1/4 bg-white border-r border-gray-300" style={{ height: 'calc(100vh - 35vh)', overflow: 'scroll' }}>
                                            <div className="overflow-y-auto h-screen p-3">
                                                {conversations.map((conversation) => (
                                                    <div
                                                        key={conversation.id}
                                                        onClick={() => handleConversationClick(conversation)}
                                                        className='flex items-center mb-4 border-b-lightborder border-b-[0.5px] cursor-pointer hover:bg-gray-100 p-2 rounded-md'
                                                        style={selectedConversation && selectedConversation.id === conversation.id ? { backgroundColor: '#F0F0F0' } : {}}
                                                    >
                                                        {/* <div className="w-12 h-12 bg-gray-300 rounded-full mr-3">
                                                            <img
                                                                src={conversation.senderProfileImage}
                                                                alt="User Avatar"
                                                                className="w-12 h-12 rounded-full"
                                                            />
                                                        </div> */}
                                                        <div className="flex-1">
                                                            <h2 className="font-bold">{conversation.senderName}</h2>
                                                            <p className='flex'>
                                                                <span className='w-1/2'>
                                                                    {conversation.lastMessage}
                                                                </span>
                                                                {/* <span className='w-1/2 flex justify-end'>
                                                                    <span
                                                                        style={{
                                                                            width: '30px',
                                                                            borderRadius: '50%',
                                                                            background: 'red',
                                                                            height: '30px',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            color: 'white',
                                                                            opacity: '70%',
                                                                        }}
                                                                    >
                                                                        {conversation.unreadCount}
                                                                    </span>
                                                                </span> */}
                                                            </p>
                                                            <p><b>{conversation.orderID && 'Order Id:'}</b> {conversation.orderID}</p>
                                                            <p><b>{conversation.orderDeliveryDate && 'Delivery Date:'}</b> {conversation.orderDeliveryDate}</p>
                                                            <p><b>{conversation.propertyAddress && 'Property Address:'}</b> {conversation.propertyAddress}</p>
                                                            <p><b>{conversation.storeLocation && 'Store Address:'}</b> {conversation.storeLocation}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        {selectedConversation && (
                                            <div className="flex-1" style={{ height: 'calc(100vh -45vh)' }}>
                                                <header className="bg-white p-4 text-gray-700 border-b border-gray-200">
                                                    <h1 className="text-2xl font-bold">{selectedConversation.senderName}</h1>
                                                </header>

                                                <div className="h-screen overflow-y-auto p-4" style={{ overflow: 'scroll', height: 'calc(100vh - 50vh)' }}>
                                                    {messages && messages.length > 0 && messages.map((message, index) => (
                                                        <div key={index}>
                                                            {message.user._id === selectedConversation.senderID ? (
                                                                <>
                                                                    <div className="flex cursor-pointer">
                                                                        <div className="w-9 h-9 rounded-full flex items-center justify-center mr-2">
                                                                            <img src={selectedConversation.senderProfileImage} alt="User Avatar" className="w-8 h-8 rounded-full" />
                                                                        </div>
                                                                        <div className="flex max-w-96 bg-red opacity-80 rounded-lg p-3 gap-3">
                                                                            <div className="flex flex-col">
                                                                                <p className="text-white">{message.text}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-black text-sm mb-4 mt-1" style={{ marginLeft: '39px' }}>{TimeAndDateFormat.fireStoreTimeStampToTime(message.createdAt)}</div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="flex justify-end mb-2 cursor-pointer">
                                                                        <div className="flex max-w-96 bg-blue text-white rounded-lg p-3 gap-3">
                                                                            <p>{message.text}</p>
                                                                        </div>
                                                                        <div className="w-9 h-9 rounded-full flex items-center justify-center ml-2">
                                                                            <img src={selectedConversation.receiverProfileImage} alt="My Avatar" className="w-8 h-8 rounded-full" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-black text-sm mb-4 text-right mr-10" style={{ marginLeft: '39px' }}>{TimeAndDateFormat.fireStoreTimeStampToTime(message.createdAt)}</div>
                                                                </>
                                                            )}
                                                        </div>
                                                    ))}
                                                    <span id='chat-scroller' ref={scroll}></span>
                                                </div>
                                                <form onSubmit={(e) => { e.preventDefault(); sendMessage(e.target.elements.message.value); e.target.reset(); }} className='w-full'>
                                                    <div className="flex items-center mt-4 ml-4">
                                                        <input
                                                            type="text"
                                                            name='message'
                                                            placeholder="Type a message..."
                                                            className="w-full p-2 rounded-md border border-gray-400 focus:outline-none focus:border-blue-500"
                                                        />
                                                        <button className="bg-blue text-white px-4 py-2 rounded-md ml-2" type='submit'>Send</button>
                                                    </div>
                                                </form>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className='flex justify-center align-items' style={{ height: 'calc(100vh - 35vh)', alignItems: 'center' }}>
                                    There is not any chat available. 
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportChat;