import { configureStore } from "@reduxjs/toolkit";

// Host Slices
import { alertSlice, allOrdersCompletedSlice, allOrdersPendingSlice, allOrdersPickedSlice, allOrdersRequestedSlice, allOrdersPaymentCompletedSlice, allOrdersSlice, orderDetailsSlice, recentOrdersSlice, signupSlice } from "./slices/adminSlices";
import { loginSlice } from "./slices/adminSlices";
import { sideNavSlice } from "./slices/adminSlices";
import { allHostsSlice, hostDeleteSlice, hostDetailSlice, hostEarningsSlice, hostPendingEarningsSlice, hostPropertiesSlice } from "./slices/HostSlices";
import { allDriversSlice, driverDeleteSlice, driverDetailSlice, driverEarningsSlice, driverOrdersSlice, driverPendingEarningsSlice } from "./slices/driverSlices";
import { propertyDeleteSlice, propertyDetailsSlice, propertyUpdateSlice } from "./slices/propertySlices";
import { packagesSlice, updatePackageSlice } from "./slices/packageSlices";
import { productsSlice } from "./slices/krogerSlices";


const adminInfoFromLocalStorage = localStorage.getItem("adminInfo")
  ? JSON.parse(localStorage.getItem("adminInfo"))
  : null;

const initialState = {
  login: { adminInfo: adminInfoFromLocalStorage }
};

export const store = configureStore({
  reducer: {
    login: loginSlice.reducer,
    signUp: signupSlice.reducer,

    // orders
    allOrders: allOrdersSlice.reducer,
    recentOrders: recentOrdersSlice.reducer,
    allOrdersCompleted: allOrdersCompletedSlice.reducer,
    allOrdersPending: allOrdersPendingSlice.reducer,
    allOrdersRequested: allOrdersRequestedSlice.reducer,
    allOrdersPaymentCompleted: allOrdersPaymentCompletedSlice.reducer,
    allOrdersPicked: allOrdersPickedSlice.reducer,
    orderDetails: orderDetailsSlice.reducer,

    //packages
    packages: packagesSlice.reducer,
    updatePackageStatus: updatePackageSlice.reducer,

    // host
    allHosts: allHostsSlice.reducer,
    hostDetails: hostDetailSlice.reducer,
    hostProperties: hostPropertiesSlice.reducer,
    deleteHost: hostDeleteSlice.reducer,
    hostEarnings: hostEarningsSlice.reducer,
    hostPendingEarnings: hostPendingEarningsSlice.reducer,

    //property
    propertyUpdate: propertyUpdateSlice.reducer,
    propertyDetails: propertyDetailsSlice.reducer,
    propertyDelete: propertyDeleteSlice.reducer,

    // drivers
    allDrivers: allDriversSlice.reducer,
    driverDetails: driverDetailSlice.reducer,
    driverOrders: driverOrdersSlice.reducer,
    driverDelete: driverDeleteSlice.reducer,
    driverEarnings: driverEarningsSlice.reducer,
    driverPendingEarnings: driverPendingEarningsSlice.reducer,

    // Kroger
    products: productsSlice.reducer,

    // sideNav
    sideNav: sideNavSlice.reducer,
    alert: alertSlice.reducer
  },
  preloadedState: initialState,
});
