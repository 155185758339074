import classNames from 'classnames';
import React from 'react';

export default function DangerButton({
    children,
    ...props
}) {
    return (
        <button
            {...props}
            className={classNames(
                'flex align-items justify-center p-1 px-4 rounded-[6px] flex gap-2 border border-red w-[70px] ml-2 text-red font-small',
                props.className,
            )}
        >
            {children}
        </button>
    );
}
