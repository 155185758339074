import { createSlice } from "@reduxjs/toolkit";
import {
  deleteHostAction,
  getAllHosts,
  getHostDetails,
  getHostEarnings,
  getHostPendingEarnings,
  getHostProperties,
} from "../thunks/HostActions";

const allHostsSlice = createSlice({
  name: "hosts",
  initialState: {
    loading: false,
    success: false,
    hostList: null,
    error: "",
  },
  reducers: {
    hostListReset: (state) => {
      state.loading = false;
      state.success = false;
      state.hostList = null;
      state.error = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllHosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllHosts.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.hostList = action.payload;
      })
      .addCase(getAllHosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostDetailSlice = createSlice({
  name: "hostDetails",
  initialState: {
    loading: false,
    success: false,
    host: null,
    error: "",
  },
  reducers: {
    hostDetailsReset: (state) => {
      state.loading = false;
      state.success = false;
      state.host = null;
      state.error = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHostDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.host = action.payload;
      })
      .addCase(getHostDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostPropertiesSlice = createSlice({
  name: "hostProperties",
  initialState: {
    loading: false,
    properties: [],
    error: "",
  },
  reducers: {
    hostPropertiesReset: (state) => {
      state.loading = false;
      state.properties = [];
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostProperties.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHostProperties.fulfilled, (state, action) => {
        state.loading = false;
        state.properties = action.payload;
      })
      .addCase(getHostProperties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.properties = []
      });
  },
});

const hostDeleteSlice = createSlice({
  name: "deleteHost",
  initialState: {
    loading: false,
    success: false,
    message: "",
    error: "",
  },
  reducers: {
    deleteHostReset: (state) => {
      state.loading = false;
      state.success = false;
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteHostAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteHostAction.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload.message;
      })
      .addCase(deleteHostAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostEarningsSlice = createSlice({
  name: "hostEarnings",
  initialState: {
    loading: false,
    success: false,
    earnings: null,
    error: "",
  },
  reducers: {
    hostEarningsReset: (state) => {
      state.loading = false;
      state.success = false;
      state.earnings = null;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostEarnings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHostEarnings.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.earnings = action.payload.orders;
      })
      .addCase(getHostEarnings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

const hostPendingEarningsSlice = createSlice({
  name: "hostEarnings",
  initialState: {
    loading: false,
    success: false,
    earnings: null,
    error: "",
  },
  reducers: {
    hostPendingEarningsReset: (state) => {
      state.loading = false;
      state.success = false;
      state.earnings = null;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHostPendingEarnings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getHostPendingEarnings.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.success;
        state.earnings = action.payload.orders;
      })
      .addCase(getHostPendingEarnings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      });
  },
});

export const { deleteHostReset } = hostDeleteSlice.actions;
export const { hostEarningsReset } = hostEarningsSlice.actions;
export const { hostPendingEarningsReset } = hostPendingEarningsSlice.actions;
export const { hostPropertiesReset } = hostPropertiesSlice.actions;
export const { hostDetailsReset } = hostDetailSlice.actions;
export const { hostListReset } = allHostsSlice.actions;

export {
  allHostsSlice,
  hostDetailSlice,
  hostPropertiesSlice,
  hostDeleteSlice,
  hostEarningsSlice,
  hostPendingEarningsSlice,
};
