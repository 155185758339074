import React from 'react'
import LoadingTableComponent from './utilityComponents/LoadingTableComponent'

const OrderFeedbackTable = ({ data, loading }) => {

    return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-[black] text-[white]">
                <tr>
                    <th className="px-4 py-3">
                        Order Id
                    </th>
                    <th className="px-4 py-3">
                        Comment
                    </th>
                    <th className="px-4 py-3">
                        Stars
                    </th>
                </tr>
            </thead>
            {loading ? (
                <LoadingTableComponent cols={3} />
            ) : (
                <tbody>
                    {
                        data?.length > 0 ? data.map((entry, index) => (
                            <tr
                                key={index}
                                className="bg-white border-b-[#CCCCCC] border-b-[0.5px] py-4"
                            >
                                <td className="px-4 w-fit min-w-[120px]">
                                    {entry.order.uniqueId}
                                </td>
    
                                <td className="px-4 w-fit min-w-[120px]">
                                    {entry.comment}
                                </td>
    
                                <td className="px-4 w-fit min-w-[120px]">
                                    {entry.stars}
                                </td>
                            </tr>
                        )) : (
                            <td className="text-center p-4 px-4 w-fit min-w-[50px]" colspan="7">
                                <p className="truncate">THERE IS NOT ANY DATA AVAILABLE HERE.</p>
                            </td>
                        )
                    }
                </tbody>
            )}
        </table>
    )
}

export default OrderFeedbackTable