import React, { useEffect, useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import { Alert } from '@mui/material';
import request from '../utils/request';
import EntriesComponent from '../components/EntriesComponent';
import PropertyOffersTable from '../components/PropertyOffersTable';
import { NavLink, useNavigate } from 'react-router-dom';

const PropertyOffers = () => {
    const [_error, _setError] = useState();
    const [data, setData] = useState();
    const [message, setMessage] = useState();
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const deleteRow = async (oid) => {
        await request.delete('/admin/property-offer/'+oid);
        setMessage('Property Offer has been deleted')
        fetchOffers()
    }

    useEffect(() => {
        fetchOffers()
    }, []);

    const fetchOffers = async () => {
        setLoading(true)
        await request.get('/admin/property-offers')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                setData([]);
            })
            .finally(() => {
                setLoading(false)
            });
        setLoading(false)
    }
    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-10 bg-white">
                    <AdminHeader
                        heading={"Property Offers"}
                        pages={"Pages / Property Offers"}
                    />
                </div>
                {message &&
                    <Alert
                        severity="success"
                        sx={{
                            position: "fixed",
                            width: "90%",
                            margin: "auto",
                            top: 20,
                            left: 0,
                            right: 0,
                            zIndex: 10,
                        }}
                    >
                        {message}
                    </Alert>
                }
                {_error &&
                    <Alert
                        severity="error"
                        sx={{
                            position: "fixed",
                            width: "90%",
                            margin: "auto",
                            top: 20,
                            left: 0,
                            right: 0,
                            zIndex: 10,
                        }}
                    >
                        {_error}
                    </Alert>
                }
                <div className="px-6 py-5 h-full overflow-auto">
                    <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 min-h-[80%]">
                        <div className="w-[95%] m-auto flex flex-col">
                            <div className="flex justify-end mt-3 mb-3">
                                <NavLink to={`/property-offers/create`} className="p-1 px-4 rounded-[21px] flex gap-2 flex justify-end bg-blue text-white">
                                    Create New Property Offer
                                </NavLink>
                            </div>
                            <div className="relative overflow-x-auto">
                                <EntriesComponent search={search} setSearch={setSearch} setLimit={setLimit} />
                                <PropertyOffersTable data={data} limit={limit} loading={loading} deleteRow={deleteRow} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PropertyOffers