import React, { useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from "@mui/material";
import PropertyOfferForm from '../components/PropertyOfferForm';
import { PropertyOfferService } from '../services/PropertyOfferService';

const PropertyOffersCreate = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([]);
    const [allProperties, setAllProperties] = useState([]);
    const [host, setHost] = useState([]);

    const [useage, setUseage] = useState({
        value: '',
        label: 'Please Select Any Option'
    });
    const [offer, setOffer] = useState({
        value: '',
        label: 'Please Select Any Option'
    });
    const [property, setProperty] = useState();


    const [formData, setFormData] = useState({
        link: null,
        usage: null
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)
        if (
            !host || host?.length < 1
            || !property || property.length < 1
            || !offer.value
            || !useage.value
            || !formData.link
        ) {
            setError('All fields are required');
            setLoading(true)
            return;
        }

        try {
            await PropertyOfferService.savePropertyOffer(host, property, allProperties, offer.value, formData.link, useage.value)
            setMessage('New property offer has been added');
            setLoading(true)
            setTimeout(() => {
                navigate('/property-offers');
            }, 1500);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchProperties = async (selectedHosts) => {
        const response = await PropertyOfferService.fetchHostProperties(selectedHosts);
        if (response?.properties?.length > 0) {
            setProperties([{
                label: 'Select All',
                value: 'all'
            }, ...response?.propertyOptionsData]);
            setAllProperties(response.properties);
            setError('');
        } else {
            setProperties([])
            setProperty();
        }
    }

    const selectDropdown = (type, options) => {
        if (type === "host") {
            setHost(options);
            fetchProperties(options);
        } else if (type === "property") {
            const option = options.filter((option) => option.value === 'all');
            if(option.length) {
                const propertyData = properties.filter((property) => property.value !== 'all');
                setProperty(propertyData)
            } else {
                setProperty(options)
            }
        } else if (type === "offer") {
            setOffer(options);
        } else if (type === "useage") {
            setUseage(options);
        }
    }

    return (
        <div className="flex w-full h-screen overflow-auto">
            <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
                <div className="px-10 bg-white">
                    <AdminHeader
                        heading={"Create New Property Offer"}
                        pages={"Pages / Offers / Create New Property Offer"}
                    />
                </div>
                <div className="px-6 py-5 h-full overflow-auto ">
                    <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 min-h-[60%] mt-1" style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        {
                            loading ? <CircularProgress /> : (
                                <PropertyOfferForm
                                    message={message}
                                    setMessage={setMessage}
                                    handleSubmit={handleSubmit}
                                    formData={{
                                        host: host,
                                        property: property,
                                        offer: offer,
                                        useage: useage,
                                        link: formData.link,
                                        
                                    }}
                                    properties={properties}
                                    setFormData={setFormData}
                                    loading={loading}
                                    error={error}
                                    selectDropdown={selectDropdown}
                                />)
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PropertyOffersCreate