import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import { useSelector } from "react-redux";
import ParserDetailsComponent from "../components/parserComponents/ParserDetailsComponent";
import { useNavigate } from "react-router-dom";

const ParserDetailScreen = () => {
  const navigate = useNavigate();
  const { host, errorDetails } = useSelector((state) => state.hostDetails);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  //   useEffect
  useEffect(() => {
    if (successMessage || errorMessage) {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
        navigate(`/hostList/${host._id}`);
      }, 2000);
    }
  }, [successMessage, errorMessage]);
  return (
    <div className="flex w-full h-screen overflow-auto">
      <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
        {/* -------------------------------- Alert Section -----------------------------------*/}

        {errorDetails && (
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <strong>{errorDetails}</strong>
          </Alert>
        )}

        {errorMessage && (
          <Alert
            severity="error"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <strong>{errorMessage}</strong>
          </Alert>
        )}

        {successMessage && (
          <Alert
            severity="success"
            sx={{
              position: "fixed",
              width: "90%",
              margin: "auto",
              top: 20,
              left: 0,
              right: 0,
              zIndex: 10,
            }}
          >
            <strong>{successMessage}</strong>
          </Alert>
        )}
        {/* --------------------------------------------------------------- */}

        <div className="px-10 bg-white">
          <AdminHeader
            heading={`${host?.firstName} ${host?.lastName}`}
            pages={"Pages / Properties Hosts List / Host"}
          />
        </div>

        <div className="px-6 py-5 h-screen overflow-auto">
          {/* <EmailAutomationComponent /> */}
          <ParserDetailsComponent
            success={successMessage}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={setErrorMessage}
          />
        </div>
      </div>
    </div>
  );
};

export default ParserDetailScreen;
