import React, { useEffect, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import HostListComponent from "../components/hostComponents/HostListComponent";
import ActivePending from "../components/ActivePending";
import { useDispatch, useSelector } from "react-redux";
import { getAllHosts } from "../store/thunks/HostActions";
import { Alert } from "@mui/material";
import { deleteHostReset, hostListReset } from "../store/slices/HostSlices";

function HostListScreen() {

  const dispatch = useDispatch()
  const [limit, setLimit] = useState(10);

  const [active,] = useState('Active Host List');
  const [pending,] = useState('Pending Hosts');
  const [activeTab, setActiveTab] = useState(active);

  // Use selector -----------------------------------
  const { loading, hostList, error } = useSelector((state) => state.allHosts)
  const { message: messageDelete, loading: loadingDelete, success: successDelete, error: errorDelete } = useSelector((state) => state.deleteHost)

  let hostData = hostList && hostList.filter((host) => {
    if (activeTab == active) {
      if (host.isVerified && host.isOnBoarded) return host
    }
    if (activeTab == pending) {
      if (!host.isVerified || !host.isOnBoarded) return host
    }
  })

  console.log("host data -> ", hostData)

  hostData = hostData && hostData.map((host, index) => {
    return {
      id: host._id,
      name: `${host.firstName} ${host.lastName}`,
      email: host.email,
      mobile: host.phoneNumber,
      emailVerified: host.isVerified === "true" ? "true" : "false",
      stripeVerified: host.isOnBoarded ? true : false
    }
  })



  useEffect(() => {

    let status = activeTab == active ? 'active' : 'pending';
    dispatch(getAllHosts({ limit, status }))
  }, [limit, activeTab])

  useEffect(() => {
    setTimeout(() => {
      error && dispatch(hostListReset())
      successDelete && dispatch(deleteHostReset())
      errorDelete && dispatch(deleteHostReset())
    }, 2000)
  }, [successDelete, errorDelete, error])

  return (
    <div className="flex w-full h-screen overflow-auto">

      {/* Error show here */}
      {successDelete && (
        <Alert
          severity="success"
          sx={{
            position: "fixed",
            width: "90%",
            margin: "auto",
            top: 20,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <strong>{messageDelete}</strong>
        </Alert>
      )}

      {errorDelete && (
        <Alert
          severity="error"
          sx={{
            position: "fixed",
            width: "90%",
            margin: "auto",
            top: 20,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <strong>{errorDelete}</strong>
        </Alert>
      )}

      {error && (
        <Alert
          severity="error"
          sx={{
            position: "fixed",
            width: "90%",
            margin: "auto",
            top: 20,
            left: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <strong>{error}</strong>
        </Alert>
      )}


      <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
        <div className="px-10 bg-white">
          <AdminHeader heading={"Properties Hosts List"} pages={"Pages / Properties Hosts List"} />
        </div>

        <div className="px-6 py-5 h-screen overflow-auto">

          <ActivePending active={active} pending={pending} activeTab={activeTab} setActiveTab={setActiveTab} />

          <HostListComponent data={hostData} setLimit={setLimit} activeTab={activeTab}  />
        </div>
      </div>
    </div>
  );
}

export default HostListScreen;
