import React, { useState, useEffect } from "react";
import TextBox2 from "../utilityComponents/TextBox2";
import edit from "../../icons/Edit.svg";
import del from "../../icons/Delete.svg";
import copy from "../../icons/copy.svg";
import back from "../../icons/left.png";
import { updateProperty } from "../../store/thunks/propertyActions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyDetails } from "../../store/thunks/propertyActions";
import { deleteProperty } from "../../store/thunks/propertyActions";
import { Alert, CircularProgress } from "@mui/material";
import { updatePropertyReset } from "../../store/slices/propertySlices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function PropertyDetailsComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { pid } = params;
  const [queryParam] = useSearchParams();
  const [editMode, setEditMode] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [copyText, setCopyText] = useState("Copy Link");

  // Property Details UseSelector Hook
  const { property: property_d } = useSelector(
    (state) => state.propertyDetails
  );


  console.log("property ->>> ", property_d)

  // Update Property UseSelector Hook
  const {
    loading: loadingUpdate,
    success: successUpdate,
    error: errorUpdate,
  } = useSelector((state) => state.propertyUpdate);

  const [address, setAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const [credentials, setCredentials] = useState({
    description: "",
    keyInfo: "",
    listingTitle: "",
  });

  const [checkInTime, setCheckInTime] = useState();
  const [checkOutTime, setCheckoutTime] = useState();

  // onClick Handlers
  const handleEditSubmit = () => {
    const propertyCredentials = { ...credentials, address, checkInTime, checkOutTime };
    console.log(propertyCredentials);
    dispatch(updateProperty({ propertyCredentials, pid }));
  };

  const handleDeleteBtn = () => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteProperty(pid));
      navigate(-1);
    }
  };

  function unsecuredCopyToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  }

  useEffect(() => {
    if (queryParam.get("edit")) {
      setEditMode(true);
      setIsReadOnly(false);
    }

    if (successUpdate) {
      setIsReadOnly(true);
      setEditMode(false);
      console.log("success if");
      navigate(-1);
    } else {
      if (!property_d?._id || property_d?._id !== pid) {
        dispatch(getPropertyDetails(pid));
      } else {
        setAddress({ ...property_d.address });
        setCredentials({
          description: property_d.description,
          keyInfo: property_d.keyInfo,
          listingTitle: property_d.listingTitle,
        });
      }
    }

    dispatch(getPropertyDetails(pid));

    console.log("asasdsdasdasd");
  }, [dispatch, pid, property_d?._id, navigate, successUpdate]);





  const handleTimeChange = (selectedTime) => {
    setCheckInTime(selectedTime);
  };

  const handleTimeChange2 = (selectedTime) => {
    setCheckoutTime(selectedTime);
  };


    const dateObject = new Date(property_d.checkInTime);

  // Format the time portion of the date object using toLocaleTimeString
  const timeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const formattedTime = dateObject.toLocaleTimeString(
    undefined,
    timeFormatOptions
  );

  // console.log("property ", property_d.checkInTime)
  // console.log("updated time ", formattedTime)

  const dateObject2 = new Date(property_d.checkOutTime);
  const checkoutFormat = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedCheckoutTime = dateObject2.toLocaleTimeString(
    undefined,
    checkoutFormat
  );








  return (
    <>
      {/* Error */}
      {errorUpdate && <Alert severity="warning">{errorUpdate}</Alert>}

      <div className="m-auto shadow-4xl pb-[30px] bg-white my-7 rounded-xl w-full tablet:w-[90%] gap-x-5 overflow-scroll">
        <div className="w-full m-auto">
          <div className="flex justify-between items-center px-5 py-3 border-b-lightborder border-b-2 w-full">
            <h1 className="text-base3 font-segoe font-semibold mobile:text-base2 flex items-center gap-x-2">
              <img
                src={back}
                alt="back"
                className="w-[30px] h-[20px] inline-block pr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <label>Property Details </label>
            </h1>

            {/* Buttons */}
            <div className="flex laptop:gap-5 tablet:gap-5 mobile:gap-0">
              <button
                className="bg-lightgrey flex items-center h-fit py-2 px-3 mobile:p-0 m-auto rounded-lg mobile:w-[40px] mobile:h-[40px] mobile:rounded-[50%] w-fit cursor-pointer"
                onClick={(e) => {
                  // navigator.clipboard.writeText(property_d.uniqueLink);
                  unsecuredCopyToClipboard(property_d.uniqueLink);

                  // alert(property_d.uniqueLink);
                  setCopyText("Copied!");
                  setTimeout(() => {
                    setCopyText("Copy Link");
                  }, 3000);
                  e.stopPropagation();
                  // Message Component
                }}
              >
                <p className="text-light font-segoe font-normal mobile:hidden w-fit min-w-[70px] flex justify-center items-center">
                  {copyText}
                </p>
                <img
                  src={copy}
                  className="w-[30px] mobile:min-w-[40px] px-2 py-1 mobile:p-3"
                  alt=""
                />
              </button>

              {!editMode ? (
                <img
                  onClick={() => {
                    setEditMode(true);
                    setIsReadOnly(false);
                  }}
                  src={edit}
                  alt="Edit"
                  className="w-[40px] mobile:w-[40px] bg-lightgrey rounded-[50%] mobile:p-3 p-2 overflow-visible cursor-pointer"
                />
              ) : (
                <button
                  onClick={() => {
                    handleEditSubmit();
                  }}
                  className="w-[100px] bg-[#00B227] text-white font-bold py-2 px-4 rounded-lg mobile:block m-auto cursor-pointer"
                >
                  {loadingUpdate ? (
                    <CircularProgress
                      color="inherit"
                      className="mr-2"
                      size={"20px"}
                    />
                  ) : (
                    "Update"
                  )}
                </button>
              )}
              <img
                onClick={handleDeleteBtn}
                src={del}
                alt="Delete"
                className="w-[40px] mobile:w-[40px] bg-lightgrey rounded-[50%] mobile:p-3 p-2 cursor-pointer"
              />
            </div>
          </div>

          <div className="w-[95%] m-auto form pt-5">
            <div className="flex items-center gap-x-7 mobile:flex-col">
              <TextBox2
                label={"Line 1"}
                name={"line1"}
                credentials={address}
                value={address.line1}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
              <TextBox2
                label={"Line 2"}
                name={"line2"}
                credentials={address}
                value={address.line2}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
            </div>

            <div className="flex items-center gap-x-7 mobile:flex-col">
              <TextBox2
                label={"City"}
                name={"city"}
                credentials={address}
                value={address.city}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
              <TextBox2
                label={"State"}
                name={"state"}
                credentials={address}
                value={address.state}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
              <TextBox2
                label={"Zip Code"}
                name={"zipCode"}
                credentials={address}
                value={address.zipCode}
                setValue={setAddress}
                readOnly={isReadOnly}
              />
            </div>
            <div className="w-full text-left mt-2">
              <TextBox2
                label={"Listing title"}
                name={"listingTitle"}
                credentials={address}
                value={credentials.listingTitle}
                setValue={setCredentials}
                readOnly={isReadOnly}
              />
            </div>
            <div className="w-full text-left mt-2">
              <label
                className="text-base1 font-normal block mb-1"
                htmlFor="message"
              >
                Description
              </label>
              <textarea
                className={`w-full bg-lightgrey rounded-xl p-2 outline-none cursor-${
                  isReadOnly ? "default" : "text"
                }`}
                id="message"
                rows="2"
                placeholder="Your message..."
                name="description"
                value={credentials.description}
                readOnly={isReadOnly}
                onChange={(e) =>
                  setCredentials({
                    ...credentials,
                    [e.target.name]: e.target.value,
                  })
                }
              ></textarea>
              <div className="w-full text-left mt-2 mobile:mt-2">
                <label
                  className="text-base1 font-normal block mb-1"
                  htmlFor="keyInfo"
                >
                  Key Location
                </label>
                <textarea
                  className={`w-full bg-lightgrey rounded-xl p-2 outline-none cursor-${
                    isReadOnly ? "default" : "text"
                  }`}
                  id="keyLocation"
                  rows="2"
                  placeholder="Your message..."
                  name="keyInfo"
                  value={credentials.keyInfo}
                  readOnly={isReadOnly}
                  onChange={(e) =>
                    setCredentials({
                      ...credentials,
                      [e.target.name]: e.target.value,
                    })
                  }
                ></textarea>
              </div>


              <div className="flex justify-between gap-[20px]">
                <div className="w-full text-left my-2">
                  <label
                    className="text-base1 font-normal block mb-1"
                    htmlFor="time"
                  >
                    <p className="font-segoe font-semibold text-[18px]">
                      Check In
                    </p>
                  </label>
                  <DatePicker
                    selected={checkInTime}
                    value={checkInTime ? checkInTime : formattedTime}
                    onChange={handleTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="w-full bg-lightgrey p-[10px] outline-none cursor-pointer rounded-xl "
                    readOnly={isReadOnly}
                  />
                </div>

                <div className="w-full text-left my-2">
                  <label
                    className="text-base1 font-normal block mb-1"
                    htmlFor="time"
                  >
                    <p className="font-segoe font-semibold text-[18px]">
                      Check Out
                    </p>
                  </label>
                  <DatePicker
                    selected={checkOutTime}
                    value={checkOutTime ? checkOutTime : formattedCheckoutTime}
                    onChange={handleTimeChange2}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className="w-full bg-lightgrey p-[10px] outline-none cursor-pointer rounded-xl "
                    readOnly={isReadOnly}
                  />
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyDetailsComponent;
