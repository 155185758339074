import React from 'react'
import AdminHeader from "../components/AdminHeader";
import DashboardComponent from '../components/DashboardComponent';


function DashboardScreen() {
  return (
    <>
    <div className="flex w-full h-screen overflow-auto">
        <div className="flex flex-col w-full px-10 mx-auto">
          <AdminHeader heading= {"Main Dashboard"}/>
            <DashboardComponent />
        </div>
      </div>


    </>
  )
}

export default DashboardScreen