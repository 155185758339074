import React, { useEffect, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import ProductCards from "../components/ProductCards";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails } from "../store/thunks/adminActions";
import { useParams } from "react-router-dom";
import InputBox from "../components/utilityComponents/InputBox";
import OrderDetailsComponent from "../components/orderComponents/OrderDetailsComponent";

function OrderDetails() {

  const dispatch = useDispatch()
  const params = useParams()
  const { oid } = params

  const { loading, order, error } = useSelector((state) => state.orderDetails)


  console.log("orderDetails", order);


  useEffect(() => {

    if (!order || order._id !== oid) {

      dispatch(getOrderDetails(oid))
    }
  }, [params])

  return (
    <div className="flex w-full h-screen overflow-auto">
      <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
        <div className="px-10 bg-white">
          <AdminHeader
            // heading={"John Doe"}
            pages={"Pages / Drivers List / Driver"}
          />
        </div>

        {order ? (

          <OrderDetailsComponent order={order} />
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full"></div>
        )}
      </div>
    </div>
  );
}

export default OrderDetails;
