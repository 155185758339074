import React from "react";

function ProductCards({product}) {

  const {name, price, quantity,image, upc, substituteOrRefund} = product

  return (
    <div className="flex gap-3 border-[0.5px] p-2 border-lightborder rounded-[6px]" >
      <div className="">
        <img src={image} className="contain max-w-[100px] max-h-[100px] rounded" alt="product-image" />
      </div>
      <div className="flex flex-col justify-around gap-2 p-2">
        <p>{name}</p>
        {upc && <p className="text-light">UPC: {upc}</p>}
        <div className="flex gap-3">
          <p className="text-light">Quantity: {quantity}</p>
          <p className="font-bold">$ {price}</p>
          <p className="font-bold">{substituteOrRefund}</p>
        </div>
      </div>
    </div>
  );
}

export default ProductCards;
