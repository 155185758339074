import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import notificationIcon from '../icons/notification.svg';
import infoIcon from '../icons/info.svg';
import backIcon from '../icons/uturn.svg';
import { db } from "../firebase";
import { collection, onSnapshot, query, where, orderBy } from 'firebase/firestore';
import { useSelector } from "react-redux";

const AdminHeader = ({ heading, pages }) => {

  const [messages, setMessages] = useState([]);

  const navigate = useNavigate();
  const { adminInfo } = useSelector((state) => state.login);

  useEffect(() => {
    const unsubscribe = fetchMessages();
    return () => unsubscribe();
  }, []);

  const fetchMessages = () => {
    const q = query(
      collection(db, 'messages'),
      where('isRead', '==', false),
      where('user._id', '!=', adminInfo._id),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const updatedMessages = [];
      querySnapshot.forEach((doc) => {
        updatedMessages.push({ id: doc.id, ...doc.data() });
      });
      setMessages(updatedMessages);
    });
    return unsubscribe;
  };

  const goToChat = () => {
    navigate('/support-chat');
  };

  return (
    <div className="flex justify-between items-center my-5">
      <div>
        <p>{pages}</p>
        <div className="flex gap-5" onClick={() => navigate(-1)}>
          <img src={backIcon} className="cursor-pointer" alt="back-icon" />
          <h1 className="text-[34px] font-segoe font-bold">{heading}</h1>
        </div>
      </div>

      <div className="flex gap-5 items-center bg-white p-5 rounded-[10px]">
        <div onClick={goToChat} className="cursor-pointer flex items-center">
          <img src={notificationIcon} alt="notification" />
          <span style={{
            marginBottom: '25px',
            width: '20px',
            borderRadius: '50%',
            background: 'red',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            opacity: '70%',
            fontSize: '12px'
          }}>{messages.length}</span>
        </div>
        <NavLink to="/help">
          <img src={infoIcon} alt="info" />
        </NavLink>
      </div>
    </div>
  );
};

export default AdminHeader;
