import React, { useState } from 'react';
import DangerButton from './DangerButton';
import DialogModal from './DialogModal';
import SecondaryButton from './SecondaryButton';

export default function DeletePropertyOffer({offerId, deleteRow}) {
    const [open, setOpen] = useState(false);

    const deleteOffer = () => {
        setOpen(false)
        deleteRow(offerId);
    }

    return (
        <div>
            <DangerButton onClick={() => setOpen(true)}>Delete</DangerButton>
            <DialogModal openModal={open} setOpenModal={setOpen}> 
                <DialogModal.Content title={'Delete User'}>
                    Are you sure you wish to delete this property offer?
                </DialogModal.Content>
                <DialogModal.Footer>
                    <SecondaryButton onClick={() => setOpen(false)}>No</SecondaryButton>
                    <DangerButton
                        onClick={() => deleteOffer(offerId)}
                    >
                        Yes
                    </DangerButton>
                </DialogModal.Footer>
            </DialogModal>
        </div>
    );
}
