import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "../../utils/request";
import { getKrogerStoreLocation, getProductsKroger } from "../../utils/kroger";

const krogerCache = (() => {
    let cache = null;
    let lastUpdated = null;

    return {
        get: () => cache,
        set: (data) => {
            cache = data;
            lastUpdated = Date.now();
        },
        isValid: () => {
            return lastUpdated !== null && Date.now() - lastUpdated < 30 * 60 * 1000; // 30 minutes in milliseconds
        },
    };
})();

const getKrogerToken = async () => {
    let data;
    if (krogerCache.isValid()) {
        // if the cache is still valid, return the cached data
        return krogerCache.get();
    } else {
        // otherwise, fetch the access token from /kroger/ and update the cache
        const { data } = await request.get("/kroger/");
        krogerCache.set(data);
        return krogerCache.get();

    }
}

const getProducts = createAsyncThunk(
    "products/getProducts",
    async (params, { rejectWithValue }) => {

        // destructuring function parameters
        const term = params?.term;
        const start = params?.start;
        const limit = params?.limit;

        try {
            // Fetching Access Token From Kroger API using backed route 
            const data = await getKrogerToken();

            // Fetching property Data From Local Storage
            // const property = localStorage.getItem("property") && JSON.parse(localStorage.getItem("property"))

            // const { lat, lng } = property.address;
            const lat = 34.0456361
            const lng = -118.2608972

            // Fetching Kroger Store LocationId From Kroger Location API 
            await getKrogerStoreLocation(data.access_token, lat, lng);

            // Fetching Products from the nearby Store
            const products = getProductsKroger(data.access_token, term, start, limit);

            return products;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export { getProducts }