export const getDateTime = (dateTimeString) => {
  if (!dateTimeString) {
    return { Date: "", Time: "" };
  }

  const dateTimeArray = dateTimeString.split(/[-T:.+]/);
  const dateComponents = dateTimeArray.slice(0, 3);
  const timeComponents = dateTimeArray.slice(3, 5);

  // Convert the date and time components to strings with separators
  const Date = dateComponents.join('-');
  
  const hour = parseInt(timeComponents[0]);
  const amPM = hour >= 12 ? 'PM' : 'AM';
  const Time = `${hour % 12 || 12}:${timeComponents[1]} ${amPM}`;

  return { Date, Time };
};
