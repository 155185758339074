import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getRecentOrders } from '../store/thunks/adminActions';
import profileIcon from '../icons/profileIcon.png'
import { NavLink } from 'react-router-dom';

const RecentOrders = () => {

    const dispatch = useDispatch();

    // use selectors ---------------------------------------
    const { orders, loading } = useSelector((state) => state.recentOrders);

    const priceMappedOrders = orders && orders.map((order)=>{
        return {
            inStockDate: order.inStockDate.substr(0, 10),
            totalAmount: (order.groceryFee + order.driverFee + order.hostFee + order.bellhoptFee).toFixed(2)
        }
    })

    useEffect(() => {
        dispatch(getRecentOrders(3))
    }, []);
    return (
        <div className="w-[28%] min-w-[225px] max-w-[500px] mr-auto p-5 shadow-4xl bg-white my-3 rounded-xl gap-x-1">
            <div className="flex justify-between">
                <h1 className="font-segoe font-bold text-[20px]">Recent orders</h1>
                <NavLink to="/orderList">view all</NavLink>
            </div>

            <div className="flex flex-col justify-between">

                {!loading && priceMappedOrders && priceMappedOrders.map((order, index) => (
                    

                    <div key={index} className="flex justify-between items-start mt-5">
                        <div className="flex gap-5 items-center content-center">
                            <img src={profileIcon} className='w-8' alt="profile Icon" />
                            <div className=''>
                                <h5 className="font-segoe font-bold">Total Revenue</h5>
                                {/* <p className="text-sm text-light">{order.inStockDate.substr(0, 10)}</p> */}
                                <p className="text-sm text-light">{order.inStockDate}</p>
                            </div>
                        </div>

                        <p className='font-semibold text-sm pt-1 min-w-[60px] text-right'>${order.totalAmount}</p>
                    </div>
                ))
                }
            </div>
        </div>
    )
}

export default RecentOrders