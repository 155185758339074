import { createSlice } from "@reduxjs/toolkit";
import { getPackages, updatePackageStatus } from "../thunks/packageActions";

const packagesSlice = createSlice({
    name: "packages",
    initialState: {
        loading: false,
        packageList: null,
        error: "",
    },
    reducers: {
        deletePackage: (state, action) => {
            console.log("delete");
            state.packageList = state.packageList.filter((pkg) => pkg.packageId !== action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPackages.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                state.loading = false;
                state.packageList = action.payload;
            })
            .addCase(getPackages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            });
    },
});

const updatePackageSlice = createSlice({
    name: "updatePackageStatus",
    initialState: {
        loading: false,
        success: false,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updatePackageStatus.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = ''
            })
            .addCase(updatePackageStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.success = action.payload?.success;
                state.error = ''
            })
            .addCase(updatePackageStatus.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action.payload?.error
            });
    },
});

export const { deletePackage } = packagesSlice.actions;
export { packagesSlice, updatePackageSlice }