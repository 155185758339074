import React, { useState } from 'react'
import { Alert } from "@mui/material";
import PackageModal from './PackageModal';
import { useSelector } from 'react-redux';


const PackageEditComponent = ({isModalOpen, setIsModalOpen, setMessage, setErrorEdit, setSuccessEdit, editPkgId }) => {

    const [error, setError] = useState(false);

    const { packageList } = useSelector((state) => state.packages)

    const editPackage = packageList?.find((pkg) => pkg.packageId === editPkgId);

    return (
        <div>
            {error && (
                <Alert
                    severity="error"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 20,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {error}
                </Alert>
            )}

            <PackageModal
                editPackage={editPackage}
                isEditMode={isModalOpen}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setMessage={setMessage}
                setError={setErrorEdit}
                setSuccess={setSuccessEdit}
                modalLabel="Edit Package"
                _error={error}
                _setError={setError}
            />
        </div>
    );
}

export default PackageEditComponent