import React from "react";
import Header from "../components/Header";
import TextBox from "../components/TextBox";
import { useState, useEffect } from "react";
import { signup } from "../store/thunks/adminActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { registrationSchema } from "../schemas";
import { signupReset } from "../store/slices/adminSlices";

const SignupScreen = () => {

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    isManager: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      state: "",
      zipCode: "",
    }
  }

  const { values, errors, touched, handleBlur, handleChange, getIn, form } = useFormik(
    {
      initialValues: initialValues,
      validationSchema: registrationSchema,
    }
  )

  // console.log("errors ", errors);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, success, error } = useSelector((state) => {
    return state.signUp;
  });
  const { adminInfo } = useSelector((state) => {
    return state.login;
  });

  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSignUp = () => {

    if (Object.keys(errors).length == 0 && values.password === confirmPassword) {

      dispatch(signup(values));
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (adminInfo) {
      navigate("/host");
    }
    if (success) {
      navigate("/please-verify");
      dispatch(signupReset());
    }
    if(error){
      setTimeout(()=>{
        dispatch(signupReset())
      }, 2000)
    }
  }, [success, navigate, adminInfo, error]);

  return (
    <>
      <div className="flex flex-col min-h-screen w-full h-fit">
        <Header />
        {error && (
          <Alert severity="error" className="">
            <strong>{error}</strong>
          </Alert>
        )}

        <div className="flex justify-center bg-white h-full w-full flex-grow">
          <div className="laptop:w-[450px] tabletOnly:w-[400px] mobile:w-[320px] text-center pb-10">
            {/* TOP */}
            <div className="top mt-5">
              <h2 className="font-bold text-lg">Sign Up</h2>
              <p className="text-base2 text-light">
                Already have an account?{" "}
                <label className=" text-primary">
                  <Link to={"/login"}>Sign In</Link>
                </label>
              </p>
            </div>

            {/* FORM */}

            <div className="form mt-[50px]">
              <div className="flex items-center gap-x-2">
                <TextBox
                  label={"First Name"}
                  name={"firstName"}
                  value={values.firstName}
                  onChange={handleChange}
                  errors={errors}
                  handleBlur={handleBlur}
                />
                <TextBox
                  label={"Last Name"}
                  name={"lastName"}
                  value={values.lastName}
                  onChange={handleChange}
                  errors={errors}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="py-1">
                <TextBox
                  label={"Email"}
                  name={"email"}
                  value={values.email}
                  onChange={handleChange}
                  errors={errors}
                  handleBlur={handleBlur}
                />
                {errors.email && touched.email ? <p className="text-[red]">{errors.email}</p> : null}
              </div>

              <div className="py-1">
                <TextBox
                  label={"Mobile Number"}
                  name={"phoneNumber"}
                  value={values.phoneNumber}
                  onChange={handleChange}
                  errors={errors}
                  handleBlur={handleBlur}
                />
                {errors.phoneNumber && touched.phoneNumber ? <p className="text-[red]">{errors.phoneNumber}</p> : null}
              </div>

              {/* Radio Buttons For isManager state */}

              <div className="flex flex-gap-5 w-full justify-between py-5">
                <label htmlFor="">Are you a Manager {errors.isManager ? <p className="text-[red] inline-block pl-1">*</p> : null} </label>

                <div className="yes_no flex">
                  <div className="mx-5">
                    <input
                      className="mx-2"
                      type="radio"
                      id="mgr"
                      name="isManager"
                      // value={values.isManager}
                      value={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="isManager">Yes</label>
                  </div>
                  <div className="mx-5">
                    <input
                      className="mx-2"
                      type="radio"
                      id="mgr"
                      name="isManager"
                      // value={values.isManager}
                      value={false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="isManager">No</label>
                  </div>
                </div>
              </div>

              {values.isManager === 'true' && (
                <div className="w-full mobile:w-full">
                  <TextBox
                    label={"Company Name"}
                    name={"companyName"}
                    type={"text"}
                    value={values.company}
                    onChange={handleChange}
                    errors={errors}
                    handleBlur={handleBlur}
                  />
                </div>
              )}

              <div className="flex items-center gap-x-7 mobile:flex-col">
                <TextBox
                  label={"Line 1"}
                  name={"address.line1"}
                  type={"text"}
                  value={values.address.line1}
                  onChange={handleChange}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />

                <TextBox
                  label={"Line 2"}
                  name={"address.line2"}
                  type={"text"}
                  value={values.address.line2}
                  onChange={handleChange}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />
              </div>
              <div className="flex items-center gap-x-7 mobile:flex-col">
                <TextBox
                  label={"City"}
                  name={"address.city"}
                  type={"text"}
                  onChange={handleChange}
                  value={values.address.city}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />
                <TextBox
                  label={"State"}
                  name={"address.state"}
                  type={"text"}
                  value={values.address.state}
                  onChange={handleChange}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />
                <TextBox
                  label={"Zip Code"}
                  name={"address.zipCode"}
                  type={"text"}
                  value={values.address.zipCode}
                  onChange={handleChange}
                  errors={errors}
                  getIn={getIn}
                  handleBlur={handleBlur}
                />
              </div>

              <TextBox
                label={"Password"}
                name={"password"}
                type={"password"}
                value={values.password}
                onChange={handleChange}
                errors={errors}
                handleBlur={handleBlur}
              />
              {errors.password && touched.password ? <p className="text-[red]">{errors.password}</p> : null}
              {/* <ErrorMessage name="email" /> */}

              <TextBox
                label={"Confirm Password"}
                name={"confirmPassword"}
                type={"password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              {values.password !== confirmPassword && <p className="text-[red]">Password does not match</p>}

              <button
                onClick={() => handleSignUp()}
                className=" bg-primary w-full h-[50px] rounded-[40px] mt-10"
              >
                <p
                  className="font-bold text-white text-base2"
                >
                  {loading ? <CircularProgress color="inherit" className="mr-2" size={'20px'} /> : "Sign Up"}
                </p>
              </button>
              {/* </form> */}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default SignupScreen;
