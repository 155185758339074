import React, { useEffect, useState } from 'react'
import AdminHeader from '../components/AdminHeader'
import ActivePending from '../components/ActivePending'
import OrdersListComponent from '../components/orderComponents/OrdersListComponent'
import { useSelector, useDispatch } from 'react-redux';
import { getAllOrders, getAllOrdersCount, getAllOrders_completed, getAllOrders_pending, getAllOrders_picked, getAllOrders_requested, getRecentOrders, getAllOrders_paymentCompleted } from '../store/thunks/adminActions';
import { useSearchParams } from 'react-router-dom';
import { getAllDrivers } from '../store/thunks/driverActions';
import { deleteDriverReset, driverListReset } from '../store/slices/driverSlices';

function OrderListScreen() {

  const dispatch = useDispatch()

  const [queryParam] = useSearchParams();

  const [limit, setLimit] = useState(10);


  // Naming convention relativ to Model is different here
  //original status pending
  const [pending,] = useState('Requested Orders');
  const [paymentCompleted,] = useState('Payment Completed Orders');
  const [completed,] = useState('Completed Orders');
  //original status picked
  const [picked,] = useState('Pending Orders');
  //original status requested
  const [requested,] = useState('Created Orders');
  const [rejected,] = useState('Rejected Orders');
  const [activeTab, setActiveTab] = useState(paymentCompleted);

  const { driverList, error } = useSelector((state) => state.allDrivers)
  const { message: messageDelete, loading: loadingDelete, successDelete, error: errorDelete } = useSelector((state) => state.driverDelete)

  let driverData = driverList && driverList.filter((driver) => {
      if (driver.isVerified && driver.checkrVerified === "clear" && driver.isOnBoarded) return driver
  })

  driverData = driverData && driverData.map((driver, index) => {
    return {
      id: driver._id,
      name: `${driver.firstName} ${driver.lastName}`,
      email: driver.email,
      mobile: driver.phoneNumber,
      checkrVerified: driver.checkrVerified,
      stripeVerified: driver.isOnBoarded ? true : false,
      emailVerified: driver.isVerified === true ? true : false
    }
  })

  useEffect(() => {
    let status = activeTab == 'active';
    dispatch(getAllDrivers({ status: true }))
  }, [limit, activeTab])

  useEffect(() => {
    setTimeout(() => {
      successDelete && dispatch(deleteDriverReset())
      errorDelete && dispatch(deleteDriverReset())
      error && dispatch(driverListReset())
    }, 2000)
  }, [successDelete, error, errorDelete])


  const { loading: loadingCompleted, orders: completedOrders, error: errprCompleted } = useSelector((state) => state.allOrdersCompleted)
  const { loading: loadingPending, orders: pendingOrders, error: errorPending } = useSelector((state) => state.allOrdersPending)
  const { loading: loadingPicked, orders: pickedOrders, error: errorPicked } = useSelector((state) => state.allOrdersPicked)
  const { loading: loadingPaymentCompleted, orders: paymentCompletedOrders, error: errorPaymentCompleted } = useSelector((state) => state.allOrdersPaymentCompleted)
  const { loading: loadingRequested, orders: requestedOrders, error: errorRequested } = useSelector((state) => state.allOrdersRequested)


  let loading;
  let filteredOrders;
  switch (activeTab) {
    case completed: {
      filteredOrders = completedOrders
      loading = loadingCompleted
      break;
    }
    case pending: {
      filteredOrders = pendingOrders
      loading = loadingPending
      break;
    }
    case picked:
      filteredOrders = pickedOrders
      loading = loadingPicked
      break;
    case paymentCompleted:
      filteredOrders = paymentCompletedOrders
      loading = loadingPaymentCompleted
      break;
    case requested:
      filteredOrders = requestedOrders
      loading = loadingRequested
      break;
  }

  useEffect(() => {
    activeTab === completed && dispatch(getAllOrders_completed({ limit }));
    activeTab === pending && dispatch(getAllOrders_pending({ limit }));
    activeTab === picked && dispatch(getAllOrders_picked({ limit }));
    activeTab === requested && dispatch(getAllOrders_requested({ limit }));
    activeTab === paymentCompleted && dispatch(getAllOrders_paymentCompleted({ limit }));
  }, [activeTab])


  useEffect(() => {
    switch (queryParam.get("status")) {
      case "completed":
        setActiveTab(completed)
        break;
      case "pending":
        setActiveTab(pending)
        break;
      case "picked":
        setActiveTab(picked)
        break;
      case "requested":
        setActiveTab(requested)
        break;
      case "rejected":
        setActiveTab(rejected)
        break;
    }
  }, [])


  return (
    <div className="flex w-full h-screen overflow-auto">
      <div className="flex flex-col w-full mx-auto bg-[#F5F5F5]">
        <div className="px-10 bg-white">
          <AdminHeader heading={"Orders List"} pages={"Pages / Orders"} />
        </div>

        <div className="px-6 py-5 h-screen overflow-auto">

          <ActivePending paymentCompleted={paymentCompleted} pending={pending} completed={completed} picked={picked} requested={requested} activeTab={activeTab} setActiveTab={setActiveTab} />

          <OrdersListComponent driverData={driverData} data={filteredOrders} loading={loading} limit={limit} setLimit={setLimit}/>
        </div>
      </div>
    </div>
  )
}

export default OrderListScreen