import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {

    const { adminInfo } = useSelector((state) => state.login)
    return (
        adminInfo ? <Outlet /> : <Navigate to="/login" />
    )
}

export default PrivateRoutes